import Grid from '@material-ui/core/Grid'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import React, { FC } from 'react'
import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDoubleField } from '../FormDoubleField'
import { FormStringField } from '../FormStringField'
import {AnnualTaxStatement} from "../../domain/businessModel/annualTaxStatement/AnnualTaxStatement";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {DoubleField} from "../../domain/businessModel/DetectedField";
import {uuid} from "../../utils/utils";
import {List} from "immutable";

interface IAnnualTaxStatementProps extends EditorFieldEvents {
    annualTaxStatement: AnnualTaxStatement
    className?: string
}
const useStyles = makeStyles(() =>
    createStyles({
        typography: {
            fontWeight: 600
        },
        dividendGrossNet: {
            '& label': {
                fontWeight: 600,
                color: 'black',
                fontSize: '1.2rem'
            }
        }
    })
)

export const AnnualTaxStatementEditor: FC<IAnnualTaxStatementProps> = (props: IAnnualTaxStatementProps) =>{
    const styles = useStyles()
    const annualTaxStatement = props.annualTaxStatement
    return (
        <form noValidate autoComplete="no">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormStringField
                        id={FieldAndColumnName.AnnualTaxStatementEditor_Year}
                        label={'Financial Year'}
                        events={props}
                        field={annualTaxStatement.year}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.AnnualTaxStatementEditor_InvestCode}
                        label={'Investment Code'}
                        events={props}
                        field={annualTaxStatement.investCode}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.AnnualTaxStatementEditor_InvestName}
                        label={'Investment Name'}
                        events={props}
                        field={annualTaxStatement.investName}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                     <FormDoubleField
                        id={FieldAndColumnName.AnnualTaxStatementEditor_NetCash}
                        label={'Net Cash Distribution'}
                        events={props}
                        field={new DoubleField(uuid(),annualTaxStatement.netCash.reduce((total,single) => total + single.parsedValue,0), [], List(), 'abc')}
                        className={props.className}
                        readonlyMode
                    />
                </Grid>
            </Grid>
        </form>
    )
}
