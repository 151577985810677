import {List, OrderedMap} from 'immutable'
import {flatten} from "../../utils/utils"
import {OcrDocument} from "../../domain/OcrDocument"
import {IOverlay} from "@bgl/textract-doc-viewer"
import {WordsVisitor} from "../../domain/docvisitor/WordsVisitor"
import {Word} from "../../domain/ocr/Word"
import BusinessModel from "../../domain/businessModel/BusinessModel"
import { adapterFromDocument } from "./IOverlayPageTransform"
import { BoundingBox, OcrElement } from "../../domain/ocr/OcrElement"
import { IPageTransform } from "../../domain/ocr/PageTransform"

function transformBoundingBoxIfNeed(element: OcrElement, pagesTransform: Map<number, IPageTransform>): BoundingBox {
  const page = pagesTransform.get(element.page)
  if (page) {
    return element.geometry.polygons.transform(page)
  } else {
    return element.geometry.boundingBox
  }
}

export function businessModelOverlay(doc: OcrDocument, businessModel?: BusinessModel): IOverlay[] {
  if (businessModel) {
    const linesUnderField = businessModel.modelSpecificLineItems().mergedLines
    const pagesTransform = new Map(adapterFromDocument(doc).map((pageOverlay) => [pageOverlay.page, pageOverlay]))
    const overlays = linesUnderField.flatMap(field => field.mergedLineFromPages.map(line => {
      return {
        id: line.id,
        boundingBox: transformBoundingBoxIfNeed(line, pagesTransform),
        page: line.page,
        hint: 'Drag to select'
      } as IOverlay
    }))
    return overlays.toArray()
  } else {
    return []
  }
}

export function wordsOverlay(doc: OcrDocument, annotationMap: OrderedMap<string, List<string>>): IOverlay[] {
  const words = flatten(doc.accept(new WordsVisitor()))
  const pagesTransform = new Map(adapterFromDocument(doc).map((pageOverlay) => [pageOverlay.page, pageOverlay]))
  const overlays = words.map((word: Word) => {
    const annotations = annotationMap.get(word.id)
    return {
      id: word.id,
      boundingBox: transformBoundingBoxIfNeed(word, pagesTransform),
      page: word.page,
      hint: annotations?.join() ? `${word.text} - ${annotations.join()}` : word.text
    } as IOverlay
  })
  return overlays.toArray()
}

export function pagesOverlay(doc: OcrDocument,  annotationMap: OrderedMap<string, List<string>>): IOverlay[] {
  const overlays = doc.pages.map((page) => {
    const annotations = annotationMap.get(page.id)
    return {
      id: page.id,
      boundingBox: page.geometry.boundingBox,
      page: page.page,
      hint: annotations ? annotations.join() : 'Drag to select'
    } as IOverlay
  })
  return overlays.toArray()
}
