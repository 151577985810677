import { BankAuthorityAccounts } from '@bgl/textract-business-model-editor'
import type { GridColDef } from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import React, { useMemo } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'

interface IBankAuthorityTableEditorProps extends EditorFieldEvents {
  accounts?: BankAuthorityAccounts
}

export const BankAuthorityAccountsTableEditor = (
  props: IBankAuthorityTableEditorProps
) => {
  const columns: GridColDef[] = useMemo(
    () => BankAuthorityAccounts.toGridColDef(),
    []
  )
  const rows = props.accounts?.toGridRowsProp() ?? []
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
    />
  )
}
