import Grid from '@material-ui/core/Grid'
import React from 'react'
import {BankAuthority} from '../../domain/businessModel/bankAuthority/BankAuthority'
import { FieldAndColumnName } from '../../domain/validator/FieldValidatorDef'
import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormStringField } from '../FormStringField'
import {BankAuthorityAccountsTableEditor} from './BankAuthorityTableEditor'
import {uuid} from "../../utils/utils";
import {FormStringFieldArray} from "../FormStringFieldArray";
import {FormDateFieldArray} from "../FormDateFieldArray";

interface IBankAuthorityEditorProps extends EditorFieldEvents {
    bankAuthority: BankAuthority
    className?: string
}

export const BankAuthorityEditor = (props: IBankAuthorityEditorProps) => {
    return (
        <form noValidate autoComplete="no">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.BankAuthorityEditor_PracticeName}
                        label={'Practice Name'}
                        events={props}
                        field={props.bankAuthority.practiceName}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.BankAuthorityEditor_AcnAbn}
                        label={'Acn ABN'}
                        events={props}
                        field={props.bankAuthority.acnAbn}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.BankAuthorityEditor_SmsfName}
                        label={'Smsf Name'}
                        events={props}
                        field={props.bankAuthority.smsfName}
                        className={props.className}
                    />
                </Grid>
                {props.bankAuthority.signatoriesName && <Grid item xs={6}>
                    <FormStringFieldArray
                        id={FieldAndColumnName.BankAuthorityEditor_SignatoriesName}
                        label={'Signatories Name'}
                        events={props}
                        fields={props.bankAuthority.signatoriesName}
                        className={props.className}
                    />
                </Grid>}
                {props.bankAuthority.signature &&
                    <Grid item xs={6}>
                        <FormStringFieldArray
                            id={FieldAndColumnName.BankAuthorityEditor_Signature}
                            label={'Signature'}
                            events={props}
                            fields={props.bankAuthority.signature}
                            className={props.className}
                        />
                    </Grid>}
                {props.bankAuthority?.signatureDate &&
                    <Grid item xs={6}>
                        <FormDateFieldArray
                            id={FieldAndColumnName.BankAuthorityEditor_SignatureDate}
                            label={'Signature Date'}
                            events={props}
                            fields={props.bankAuthority.signatureDate}
                            className={props.className}
                        />
                    </Grid>}
                <Grid item xs={12}>
                    <BankAuthorityAccountsTableEditor
                        accounts={props.bankAuthority?.accounts}
                    />
                </Grid>
            </Grid>
        </form>
    )
}
