import autobind from 'autobind-decorator'
import { OcrDocument } from 'domain/OcrDocument'
import {List} from 'immutable'
import React, {FunctionComponent} from 'react'
import {PageVisitor, TableVisitor} from '../../domain/DocumentVisitor'
import {Cell} from '../../domain/ocr/Cell'
import {Page} from '../../domain/ocr/Page'
import {Table} from '../../domain/ocr/Table'
import {flatten} from '../../utils/utils'
import {TableEditor} from './TableEditor'

interface IDocumentTableEditorProp {
  document: OcrDocument
  onCellClicked?: (table: Table, cell: Cell) => void
}

@autobind
class TablesVisitor implements PageVisitor<List<Table>>, TableVisitor<Table> {
  visitPage(page: Page): List<Table> {
    return page.acceptTableVisitor(this)
  }

  visitTable(table: Table): Table {
    return table
  }
}

export const DocumentTableEditor: FunctionComponent<IDocumentTableEditorProp> = (tableProps: IDocumentTableEditorProp) => {
  const document = tableProps.document
  const tables: List<Table> = flatten(document.accept(new TablesVisitor()))
  return (
    <>
      {
        tables.map(table => <TableEditor key={table.id} table={table} onCellClicked={tableProps.onCellClicked}/>)
      }
    </>
  )
}
