import Grid from '@material-ui/core/Grid'
import React from 'react'
import { AtoQuarterlyPaygInstalmentNotice } from '../../domain/businessModel/atoQuarterlyPaygInstalmentNotice/AtoQuarterlyPaygInstalmentNotice'
import { FieldAndColumnName } from '../../domain/validator/FieldValidatorDef'
import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormDollarField } from '../FormDollarField'
import { FormStringField } from '../FormStringField'

interface IPagyInstalmentReportEditorProps extends EditorFieldEvents {
  atoQuarterlyPaygInstalmentNotice: AtoQuarterlyPaygInstalmentNotice
  className?: string
}

export const AtoQuarterlyPaygInstalmentNoticeEditor = (props: IPagyInstalmentReportEditorProps) => {
  return (
    <form noValidate autoComplete="no">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_DocumentId}
            label={'Document Id'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.documentId}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_Abn}
            label={'ABN'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.abn}
            className={props.className}
          />
      </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_Recipient}
            label={'Recipient'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.recipient}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_Address}
            label={'Address'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.address}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_Quarter}
            label={'Quarter'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.quarter}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_Year}
            label={'Year'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.year}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_FormDueOn}
            label={'Form Due On'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.formDueOn}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_PaymentDueOn}
            label={'Payment Due On'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.paymentDueOn}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_InstalmentAmount}
            label={'Instalment Amount'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.instalmentAmount}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_AtoCode}
            label={'Ato Code'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.atoCode}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.AtoQuarterlyPaygInstalmentNoticeEditor_EftCode}
            label={'Eft Code'}
            events={props}
            field={props.atoQuarterlyPaygInstalmentNotice.eftCode}
            className={props.className}
          />
        </Grid>
      </Grid>
    </form>
  )
}
