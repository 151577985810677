import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DoubleField, StringField} from '../DetectedField'
import { AnnualTaxStatementMapper } from './AnnualTaxStatementMapper'


export class AnnualTaxStatement extends BusinessModel {
  constructor(
      modelType: IModelType,
      version: string,
      readonly year?:StringField,
      readonly investName?: StringField,
      readonly investCode?: StringField,
      readonly otherIncome?: DoubleField[],
      readonly interest?: DoubleField[],
      readonly grossFranked?: DoubleField[],
      readonly frankingCredits?: DoubleField[],
      readonly unfranked?: DoubleField[],
      readonly netFranked?: DoubleField[],
      readonly discountCG?: DoubleField[],
      readonly otherCG?: DoubleField[],
      readonly cgtConcession?: DoubleField[],
      readonly discountForCGCredits?: DoubleField[],
      readonly otherForCGCredits?: DoubleField[],
      readonly discountForCG?: DoubleField[],
      readonly otherForCG?: DoubleField[],
      readonly indexCG?: DoubleField[],
      readonly grossOtherForeignIncome?: DoubleField[],
      readonly foreignCredits?: DoubleField[],
      readonly grossForeignInc?: DoubleField[],
      readonly NZfrankingCredits?: DoubleField[],
      readonly taxDeferred?: DoubleField[],
      readonly taxExempt?: DoubleField[],
      readonly taxFree?: DoubleField[],
      readonly amitExcess?:DoubleField[],
      readonly amitShortfall?:DoubleField[],
      readonly tfnCredits?:DoubleField[],
      readonly trustDeduction?:DoubleField[],
      readonly otherExpenses?:DoubleField[],
      readonly netCash: DoubleField[] = [],
      readonly netForeignIncome?: DoubleField[]
  ){
    super(modelType, version)
  }

  copy({
            modelType =this.modelType,
            version=this.version,
            year = this.year,
            investName = this.investName,
            investCode = this.investCode,
            otherIncome = this.otherIncome,
            interest = this.interest,
            grossFranked = this.grossFranked,
            frankingCredits = this.frankingCredits,
            unfranked = this.unfranked,
            netFranked = this.netFranked,
            discountCG = this.discountCG,
            otherCG = this.otherCG,
            cgtConcession = this.cgtConcession,
            discountForCGCredits = this.discountForCGCredits,
            otherForCGCredits =this.otherForCGCredits,
            discountForCG = this.discountForCG,
            otherForCG = this.otherForCG,
            indexCG = this.indexCG,
            grossOtherForeignIncome = this.grossOtherForeignIncome,
            foreignCredits = this.foreignCredits,
            grossForeignInc = this.grossForeignInc,
            NZfrankingCredits = this.NZfrankingCredits,
            taxDeferred = this.taxDeferred,
            taxExempt = this.taxExempt,
            taxFree = this.taxFree,
            amitExcess = this.amitExcess,
            amitShortfall = this.amitShortfall,
            tfnCredits=this.tfnCredits,
            trustDeduction = this.trustDeduction,
            otherExpenses= this.otherExpenses,
            netCash = this.netCash,
            netForeignIncome = this.netForeignIncome
       }):AnnualTaxStatement {
    return new AnnualTaxStatement(
        modelType,
        version,
        year,
        investName,
        investCode,
        otherIncome,
        interest,
        grossFranked,
        frankingCredits,
        unfranked,
        netFranked,
        discountCG,
        otherCG,
        cgtConcession,
        discountForCGCredits,
        otherForCGCredits,
        discountForCG,
        otherForCG,
        indexCG,
        grossOtherForeignIncome,
        foreignCredits,
        grossForeignInc,
        NZfrankingCredits,
        taxDeferred,
        taxExempt,
        taxFree,
        amitExcess,
        amitShortfall,
        tfnCredits,
        trustDeduction,
        otherExpenses,
        netCash,
        netForeignIncome
    )
  }

  toJson(): IBusinessModel {
    return AnnualTaxStatementMapper.toJson(this)
  }
}
