import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {IBusinessModel, IDividendStatementModel, IModelType} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import { DividendStatement } from './DividendStatement'

@autoBind
export class DividendStatementMapper extends BusinessModelMapper {
  constructor(
     document:OcrDocument,
     private readonly jsonModel: IDividendStatementModel
  ){
    super(document)
  }

  fromJson(): DividendStatement {
    const dividendStatementModel = this.jsonModel
    const model = new DividendStatement(
        dividendStatementModel.Version,
        this.mapTextBlock(dividendStatementModel.ShareCode),
        this.mapDoubleBlock(dividendStatementModel.DividendFranked),
        this.mapDoubleBlock(dividendStatementModel.DividendUnfranked),
        this.mapDoubleBlock(dividendStatementModel.FrankingCredits),
        this.mapDoubleBlock(dividendStatementModel.AssessableForeignIncome),
        this.mapDoubleBlock(dividendStatementModel.ForeignIncomeTaxOffset),
        this.mapDoubleBlock(dividendStatementModel.FrankingCreditsFromNZ),
        this.mapDoubleBlock(dividendStatementModel.WithholdingTax),
        this.mapDoubleBlock(dividendStatementModel.ConvertedNetDividend),
        this.mapDoubleBlock(dividendStatementModel.IndividualTrustLIC),
        this.mapDoubleBlock(dividendStatementModel.SMSFLIC),
        this.mapDateBlock(dividendStatementModel.PaymentDate),
        this.mapDateBlock(dividendStatementModel.RecordDate),
        this.mapDoubleBlock(dividendStatementModel.NumberOfShares),
        this.mapDoubleBlock(dividendStatementModel.DividendPerShare),
        this.mapDoubleBlock(dividendStatementModel.GrossDividend),
        this.mapDoubleBlock(dividendStatementModel.NetDividend),
        this.mapDoubleBlock(dividendStatementModel.DrpNumberOfShares),
        this.mapDoubleBlock(dividendStatementModel.PreDRPBalanceBringForward),
        this.mapDoubleBlock(dividendStatementModel.DrpBalanceAvailable),
        this.mapDoubleBlock(dividendStatementModel.DrpSharePrice),
        this.mapTextBlock(dividendStatementModel.PostDRPShareNumber),
        this.mapDoubleBlock(dividendStatementModel.PostDRPCreditBalance),
        this.mapDoubleBlock(dividendStatementModel.CompanyTaxRate),
        this.mapDoubleBlock(dividendStatementModel.FrankingPercentage),
        this.mapDoubleBlock(dividendStatementModel.DrpShareCost),
        this.mapDoubleBlock(dividendStatementModel.ExchangeRate),
        this.mapTextBlock(dividendStatementModel.ForeignCurrency),
        this.mapDoubleBlock(dividendStatementModel.SupplementaryDividendNZ),
        this.mapDoubleBlock(dividendStatementModel.FeeDeduction),
        this.mapDoubleBlock(dividendStatementModel.CapitalReturn),
        this.mapDoubleBlock(dividendStatementModel.CapitalComponentPerShare)
    )
    return model
  }

  static toJson(model: DividendStatement): IBusinessModel {
    return {
        ModelType: IModelType.DividendStatement,
        Version: model.version,
        ShareCode: model.shareCode?.toModelKeyValue(),
        DividendFranked: model.dividendFranked?.toModelKeyValue(),
        DividendUnfranked: model.dividendUnfranked?.toModelKeyValue(),
        FrankingCredits: model.frankingCredits?.toModelKeyValue(),
        AssessableForeignIncome: model.assessableForeignIncome?.toModelKeyValue(),
        ForeignIncomeTaxOffset: model.foreignIncomeTaxOffset?.toModelKeyValue(),
        FrankingCreditsFromNZ: model.frankingCreditsFromNZ?.toModelKeyValue(),
        WithholdingTax: model.withholdingTax?.toModelKeyValue(),
        ConvertedNetDividend: model.convertedNetDividend?.toModelKeyValue(),
        IndividualTrustLIC: model.IndividualTrustLIC?.toModelKeyValue(),
        SMSFLIC: model.SMSFLIC?.toModelKeyValue(),
        PaymentDate: model.paymentDate?.toModelKeyValue(),
        RecordDate: model.recordDate?.toModelKeyValue(),
        NumberOfShares: model.numberOfShares?.toModelKeyValue(),
        DividendPerShare: model.dividendPerShare?.toModelKeyValue(),
        GrossDividend: model.grossDividend?.toModelKeyValue(),
        NetDividend: model.netDividend?.toModelKeyValue(),
        DrpNumberOfShares: model.drpNumberOfShares?.toModelKeyValue(),
        PreDRPBalanceBringForward: model.preDRPBalanceBringForward?.toModelKeyValue(),
        DrpBalanceAvailable: model.drpBalanceAvailable?.toModelKeyValue(),
        DrpSharePrice: model.drpSharePrice?.toModelKeyValue(),
        PostDRPShareNumber: model.postDRPShareNumber?.toModelKeyValue(),
        PostDRPCreditBalance: model.postDRPCreditBalance?.toModelKeyValue(),
        CompanyTaxRate: model.companyTaxRate?.toModelKeyValue(),
        FrankingPercentage: model.frankingPercentage?.toModelKeyValue(),
        DrpShareCost: model.drpShareCost?.toModelKeyValue(),
        ExchangeRate: model.exchangeRate?.toModelKeyValue(),
        ForeignCurrency: model.foreignCurrency?.toModelKeyValue(),
        SupplementaryDividendNZ: model.supplementaryDividendNZ?.toModelKeyValue(),
        FeeDeduction: model.feeDeduction?.toModelKeyValue(),
        CapitalReturn: model.capitalReturn?.toModelKeyValue(),
        CapitalComponentPerShare:model.capitalComponentPerShare?.toModelKeyValue()
    } as IDividendStatementModel
  }
}
