import SmartDocImageAnnotator, {
  DEFAULT_TAG_ID,
  IAnnotatorAnnotation,
  IAnnotatorPageImage,
  Rect
} from '@bgl/smartdoc-image-annotator'
import { BaseShape, DrawStyle } from '@bgl/smartdoc-image-annotator'
import {CreateType} from '@bgl/smartdoc-image-annotator/dist/models/Types'
import { ObjectAnnotation, ObjectAnnotationsOnFile} from 'domain/ObjectAnnotation'
import { Map } from 'immutable'
import React from 'react'
import { objectAnnotationBuilder } from './ObjectAnnotationBuilder'

export interface IImageAnnotatorViewer {
  pageImages: IAnnotatorPageImage[],
  objectAnnotations: ObjectAnnotationsOnFile,
  hideLabels: string[],
  setSelectedObject: (object: ObjectAnnotation | undefined) => void,
  selectedObject: ObjectAnnotation | undefined,
  onObjectUpdated: (object: ObjectAnnotation) => void,
  onDeletedObject: (object: ObjectAnnotation) => void,
  onNewCreatedObject: (object: ObjectAnnotation) => void,
  setCurrentPage: (pageId: string) => void
  colorMap: Map<string, string>,
  tagIdToNameMap: Map<string, string>,
  defaultCreateType: CreateType,
  options?: {
    defaultDrawStyle: DrawStyle,
    defaultLabelName: string
  }
}

const LABEL_CONFIDENCE = 1

export default function ImageAnnotatorViewer(props: IImageAnnotatorViewer) {

  const annotations: IAnnotatorAnnotation[] = objectAnnotationBuilder(props.objectAnnotations, props.colorMap, props.tagIdToNameMap, props.hideLabels, props.selectedObject)

  const shapeToObjectAnnotation = (shape: BaseShape): ObjectAnnotation => {
    return new ObjectAnnotation(shape.shapeId, shape.tagId || DEFAULT_TAG_ID, LABEL_CONFIDENCE, shape.getAnnotationCoor(), 'smartdoc-builder')
  }

  return (
    <SmartDocImageAnnotator
      pageImages={props.pageImages}
      annotations={annotations}
      setSelectedShape={shape => {
          if(!shape) return props.setSelectedObject(undefined)
          const annotation = shapeToObjectAnnotation(shape)
          props.setSelectedObject(annotation)
      }}
      onShapeDeleted={shape => {
        const annotation = shapeToObjectAnnotation(shape)
        props.onDeletedObject(annotation)
      }}
      onShapeUpdated={shape => {
        const annotation = shapeToObjectAnnotation(shape)
        props.onObjectUpdated(annotation)
      }}
      onShapeCreated={
        shape => {
          const annotation = shapeToObjectAnnotation(shape)
          props.onNewCreatedObject(annotation)
        }
      }
      setCurrentPage= {props.setCurrentPage}
      options={props.options}
      defaultCreateType={props.defaultCreateType}
    />
  )
}
