import { IModelType } from 'domain/types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import { BusinessModelTypePageTable } from './BusinessModelTypePageTable'

export class BusinessModelType extends BusinessModel {
  constructor(
    version: string,
    readonly modelName: string,
    readonly probability: number,
    readonly businessModelTypePages: BusinessModelTypePageTable
  ) {
    super(IModelType.BusinessModelType, version)
  }
}
