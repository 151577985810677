import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {
    IAnnualTaxStatementModel,
    IBusinessModel,
    IModelType,
} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {AnnualTaxStatement} from './AnnualTaxStatement'

@autoBind
export class AnnualTaxStatementMapper extends BusinessModelMapper {
    constructor(
      document: OcrDocument,
      private readonly jsonModel: IAnnualTaxStatementModel
    ) {
        super(document)
    }

    fromJson(): AnnualTaxStatement {
        const AnnualTaxStatementModel = this.jsonModel
        const model = new AnnualTaxStatement(
          IModelType.AnnualTaxStatement,
          AnnualTaxStatementModel.Version,
          this.mapTextBlock(AnnualTaxStatementModel.Year),
          this.mapTextBlock(AnnualTaxStatementModel.InvestName),
          this.mapTextBlock(AnnualTaxStatementModel.InvestCode),
          this.mapDoubleBlocks(AnnualTaxStatementModel.OtherIncome),
          this.mapDoubleBlocks(AnnualTaxStatementModel.Interest),
          this.mapDoubleBlocks(AnnualTaxStatementModel.GrossFranked),
          this.mapDoubleBlocks(AnnualTaxStatementModel.FrankingCredits),
          this.mapDoubleBlocks(AnnualTaxStatementModel.Unfranked),
          this.mapDoubleBlocks(AnnualTaxStatementModel.NetFranked),
          this.mapDoubleBlocks(AnnualTaxStatementModel.DiscountCG),
          this.mapDoubleBlocks(AnnualTaxStatementModel.OtherCG),
          this.mapDoubleBlocks(AnnualTaxStatementModel.CgtConcession),
          this.mapDoubleBlocks(AnnualTaxStatementModel.DiscountForCGCredits),
          this.mapDoubleBlocks(AnnualTaxStatementModel.OtherForCGCredits),
          this.mapDoubleBlocks(AnnualTaxStatementModel.DiscountForCG),
          this.mapDoubleBlocks(AnnualTaxStatementModel.OtherForCG),
          this.mapDoubleBlocks(AnnualTaxStatementModel.IndexCG),
          this.mapDoubleBlocks(AnnualTaxStatementModel.GrossOtherForeignIncome),
          this.mapDoubleBlocks(AnnualTaxStatementModel.ForeignCredits),
          this.mapDoubleBlocks(AnnualTaxStatementModel.GrossForeignInc),
          this.mapDoubleBlocks(AnnualTaxStatementModel.NZfrankingCredits),
          this.mapDoubleBlocks(AnnualTaxStatementModel.TaxDeferred),
          this.mapDoubleBlocks(AnnualTaxStatementModel.TaxExempt),
          this.mapDoubleBlocks(AnnualTaxStatementModel.TaxFree),
          this.mapDoubleBlocks(AnnualTaxStatementModel.AmitExcess),
          this.mapDoubleBlocks(AnnualTaxStatementModel.AmitShortfall),
          this.mapDoubleBlocks(AnnualTaxStatementModel.TfnCredits),
          this.mapDoubleBlocks(AnnualTaxStatementModel.TrustDeduction),
          this.mapDoubleBlocks(AnnualTaxStatementModel.OtherExpenses),
          this.mapDoubleBlocks(AnnualTaxStatementModel.NetCash),
          this.mapDoubleBlocks(AnnualTaxStatementModel.NetForeignIncome)
        )
        return model
    }

    static toJson(model: AnnualTaxStatement): IBusinessModel {
        return {
            ModelType: IModelType.AnnualTaxStatement,
            Version: model.version,
            Year: model.year?.toModelKeyValue(),
            InvestName: model.investName?.toModelKeyValue(),
            InvestCode: model.investCode?.toModelKeyValue(),
            OtherIncome: model.otherIncome?.map(it => it.toModelKeyValue()),
            Interest: model.interest?.map(it => it.toModelKeyValue()),
            GrossFranked: model.grossFranked?.map(it => it.toModelKeyValue()),
            FrankingCredits: model.frankingCredits?.map(it => it.toModelKeyValue()),
            Unfranked: model.unfranked?.map(it => it.toModelKeyValue()),
            NetFranked: model.netFranked?.map(it => it.toModelKeyValue()),
            DiscountCG: model.discountCG?.map(it => it.toModelKeyValue()),
            OtherCG: model.otherCG?.map(it => it.toModelKeyValue()),
            CgtConcession: model.cgtConcession?.map(it => it.toModelKeyValue()),
            DiscountForCGCredits: model.discountForCGCredits?.map(it => it.toModelKeyValue()),
            OtherForCGCredits: model.otherForCGCredits?.map(it => it.toModelKeyValue()),
            DiscountForCG: model.discountForCG?.map(it => it.toModelKeyValue()),
            OtherForCG: model.otherForCG?.map(it => it.toModelKeyValue()),
            IndexCG: model.indexCG?.map(it => it.toModelKeyValue()),
            GrossOtherForeignIncome: model.grossOtherForeignIncome?.map(it => it.toModelKeyValue()),
            ForeignCredits: model.foreignCredits?.map(it => it.toModelKeyValue()),
            GrossForeignInc: model.grossForeignInc?.map(it => it.toModelKeyValue()),
            NZfrankingCredits: model.NZfrankingCredits?.map(it => it.toModelKeyValue()),
            TaxDeferred: model.taxDeferred?.map(it => it.toModelKeyValue()),
            TaxExempt: model.cgtConcession?.map(it => it.toModelKeyValue()),
            TaxFree: model.taxFree?.map(it => it.toModelKeyValue()),
            AmitExcess: model.amitExcess?.map(it => it.toModelKeyValue()),
            AmitShortfall: model.amitShortfall?.map(it => it.toModelKeyValue()),
            TfnCredits: model.tfnCredits?.map(it => it.toModelKeyValue()),
            TrustDeduction: model.trustDeduction?.map(it => it.toModelKeyValue()),
            OtherExpenses: model.otherExpenses?.map(it => it.toModelKeyValue()),
            NetCash: model.netCash?.map(it => it.toModelKeyValue()),
            NetForeignIncome: model.netForeignIncome?.map(it => it.toModelKeyValue())
        } as IAnnualTaxStatementModel
    }
}