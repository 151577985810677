import {GridColDef, GridRowsProp} from '@material-ui/data-grid'
import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import moment from 'moment'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import BusinessModel from '../BusinessModel'
import {DateField, DetectedField, DoubleField, StringField} from '../DetectedField'
import {ReceiptItem} from './ReceiptItem'
import { ReceiptMapper } from './ReceiptMapper'

export class Receipt extends BusinessModel {
  constructor(
    version: string,
    readonly items: List<ReceiptItem>,
    readonly receiptType?: StringField,
    readonly merchantName?: StringField,
    readonly merchantPhoneNumber?: StringField,
    readonly merchantAddress?: StringField,
    readonly transactionDate?: DateField,
    readonly transactionTime?: StringField,
    readonly total?: DoubleField,
    readonly subtotal?: DoubleField,
    readonly tax?: DoubleField,
    readonly tip?: DoubleField
  ) {
    super(IModelType.Receipt, version)
  }

  copy({
    version = this.version,
    items = this.items,
    receiptType = this.receiptType,
    merchantName = this.merchantName,
    merchantPhoneNumber = this.merchantPhoneNumber,
    merchantAddress = this.merchantAddress,
    transactionDate = this.transactionDate,
    transactionTime = this.transactionTime,
    total = this.total,
    subtotal = this.subtotal,
    tax = this.tax,
    tip = this.tip
  }): Receipt {
    return new Receipt(
      version,
      items,
      receiptType,
      merchantName,
      merchantPhoneNumber,
      merchantAddress,
      transactionDate,
      transactionTime,
      total,
      subtotal,
      tax,
      tip
    )
  }

  protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    switch (id) {
      case FieldAndColumnName.ReceiptEditor_MerchantName:
      case FieldAndColumnName.ReceiptEditor_MerchantPhoneNumber:
      case FieldAndColumnName.ReceiptEditor_MerchantAddress:
      case FieldAndColumnName.ReceiptEditor_TransactionTime:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)

      case FieldAndColumnName.ReceiptEditor_TransactionDate:
        return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)

      case FieldAndColumnName.ReceiptEditor_Total:
      case FieldAndColumnName.ReceiptEditor_Subtotal:
      case FieldAndColumnName.ReceiptEditor_Tax:
      case FieldAndColumnName.ReceiptEditor_Tip:
        return new DoubleField(uuid(), value as number, [], List(), modifiedBy)

      default:
        return undefined
    }
  }

  protected getGridMember(): List<ReceiptItem> {
    return this.items
  }

  protected listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this).concat(
      this.items.flatMap(item => item.listFields())
    )
  }

  byIndex(index: number): ReceiptItem | undefined {
    return this.items.get(index)
  }

  static toGridColDef(): GridColDef[] {
    return [
      {
        field: FieldAndColumnName.ReceiptItemColumn_Name,
        headerName: 'ITEM',
        description: 'Item',
        width: 300,
        editable: true
      },
      {
        field: FieldAndColumnName.ReceiptItemColumn_Quantity,
        headerName: 'QUANTITY',
        description: 'Quantity',
        width: 100,
        editable: true,
        type: 'string'
      },
      {
        field: FieldAndColumnName.ReceiptItemColumn_Price,
        headerName: 'PRICE',
        description: 'Price',
        width: 100,
        editable: true,
        type: 'string'
      },
      {
        field: FieldAndColumnName.ReceiptItemColumn_TotalPrice,
        headerName: 'TOTAL',
        description: 'Total Price',
        width: 100,
        editable: true,
        type: 'string'
      }
    ]
  }

  toGridRowsProp(): GridRowsProp {
    return this.items.map((receiptItem, index) => {
      return receiptItem.toGridRow(index)
    }).toArray()
  }

  toJson(): IBusinessModel {
      return ReceiptMapper.toJson(this)
  }

  getPeriodStartDate(): moment.Moment | undefined {
    return this.transactionDate?.parsedValue
  }

  getPeriodEndDate(): moment.Moment | undefined {
    return this.transactionDate?.parsedValue
  }

  getTotalAmount(): number {
    return this.total?.parsedValue || 0
  }
}
