import React, {FunctionComponent} from 'react'
import Grid from "@material-ui/core/Grid"
import {Receipt} from "../../domain/businessModel/receipt/Receipt"
import {FormStringField} from "../FormStringField"
import {FormDateField} from "../FormDateField"
import {FormDoubleField} from "../FormDoubleField";
import {ReceiptItemsEditor} from "./ReceiptItemsEditor"
import {EditorFieldEvents} from "../EditorFieldEvents"
import {ReceiptItem} from "../../domain/businessModel/receipt/ReceiptItem"
import {DetectedField} from "../../domain/businessModel/DetectedField"
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'

interface IReceiptProps extends EditorFieldEvents{
  receiptProps: Receipt,
  className?: string
}

export const ReceiptEditor: FunctionComponent<IReceiptProps> = (props: IReceiptProps) => {
  function onCellClick(receipt: Receipt, item: ReceiptItem, field: DetectedField) {
    props.onFieldFocus?.(undefined, field)
  }

  const receipt = props.receiptProps

  return (
    <form noValidate autoComplete="on">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormStringField
            id={FieldAndColumnName.ReceiptEditor_MerchantName}
            label={"Merchant"}
            events={props}
            field={receipt.merchantName}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ReceiptEditor_MerchantPhoneNumber}
            label={"Merchant Phone Number"}
            events={props}
            field={receipt.merchantPhoneNumber}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ReceiptEditor_MerchantAddress}
            label={"Merchant Address"}
            events={props}
            field={receipt.merchantAddress}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.ReceiptEditor_TransactionDate}
            label={"Transaction Date"}
            events={props}
            field={receipt.transactionDate}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ReceiptEditor_TransactionTime}
            label={"Transaction Time"}
            events={props}
            field={receipt.transactionTime}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDoubleField
            id={FieldAndColumnName.ReceiptEditor_Total}
            label={"Total"}
            events={props}
            field={receipt.total}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDoubleField
            id={FieldAndColumnName.ReceiptEditor_Subtotal}
            label={"Subtotal"}
            events={props}
            field={receipt.subtotal}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDoubleField
            id={FieldAndColumnName.ReceiptEditor_Tax}
            label={"GST"}
            events={props}
            field={receipt.tax}
            className={props.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDoubleField
            id={FieldAndColumnName.ReceiptEditor_Tip}
            label={"Tip"}
            events={props}
            field={receipt.tip}
            className={props.className}
          />
        </Grid>
        <Grid item xs={12}>
          <ReceiptItemsEditor
              receipt={receipt}
              onCellClick={onCellClick}
              onGridValueChanged={props.onGridValueChanged}
          />
        </Grid>
      </Grid>
    </form>
  )
}
