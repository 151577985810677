import {TextField} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, { FunctionComponent, useMemo } from 'react'
import useFormControl from 'utils/hooks/useFormControl'
import {BankStatement} from '../../domain/businessModel/bankstatement/BankStatement'
import {Transaction} from '../../domain/businessModel/bankstatement/Transaction'
import {Transactions} from '../../domain/businessModel/bankstatement/Transactions'
import {DetectedField} from '../../domain/businessModel/DetectedField'
import {EditorFieldEvents} from '../EditorFieldEvents'
import {FormDateField} from '../FormDateField'
import {FormStringField} from '../FormStringField'
import {TransactionTableEditor} from './TransactionTableEditor'

import { GridRowsProp } from '@material-ui/data-grid'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { FormFieldValidator } from 'domain/validator/FormFieldValidator'
import { FormControlContext } from 'utils/context/FormControlContext'
import { BankStatementValidatorFactory } from '../../domain/validator/BankStatementFieldValidator'
import { TransactionTableCellValidatorFactory } from '../../domain/validator/TransactionTableCellValidator'
import {FormDollarField} from '../FormDollarField'

interface BankStatementProps extends EditorFieldEvents {
  bankStatement: BankStatement,
  className?: string
  readonlyMode?: boolean
  tableReadOnly?: boolean
}

const fieldNames = [
  FieldAndColumnName.BankStatementEditor_AccountNumber,
  FieldAndColumnName.BankStatementEditor_BSB,
  FieldAndColumnName.BankStatementEditor_StartDate,
  FieldAndColumnName.BankStatementEditor_EndDate
]

const columnNames = [
  FieldAndColumnName.TransactionTable_Date,
  FieldAndColumnName.TransactionTable_TotalDebit,
  FieldAndColumnName.TransactionTable_TotalCredit,
  FieldAndColumnName.TransactionTable_OpeningBalance
]

const fieldValidators = FormFieldValidator.generateValidators(BankStatementValidatorFactory, fieldNames)
const gridValidators = FormFieldValidator.generateValidators(TransactionTableCellValidatorFactory, columnNames)

export const BankStatementEditor: FunctionComponent<BankStatementProps> = (bsProps: BankStatementProps) => {
  const transactionTable = bsProps.bankStatement.firstTable()

  const rows: GridRowsProp = useMemo(() => transactionTable?.transactions?.toGridRowsProp() ?? [], [transactionTable])

  const { handleInputValue, handleGridInputValue, errors, gridErrors } = useFormControl(
    bsProps.fieldValidators || fieldValidators,
    transactionTable,
    bsProps.gridValidators || gridValidators,
    rows,
    bsProps.notifyError
  )

  function onCellClicked(transactions: Transactions, transaction: Transaction, field: DetectedField) {
    if (bsProps.onFieldFocus) {
      bsProps.onFieldFocus(undefined, field)
    }
  }

  return (
    <FormControlContext.Provider value={{ handleInputValue, handleGridInputValue, errors, gridErrors, record: transactionTable }}>
      <form noValidate autoComplete="on">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
                label="Bank Name"
                defaultValue={bsProps.bankStatement.bankName}
                placeholder="N/A"
                fullWidth
                className={bsProps.className}
                disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.BankStatementEditor_FinancialInstitution}
                label={'Financial Institution'}
                events={bsProps}
                field={bsProps.bankStatement.financialInstitution}
                className={bsProps.className}
                readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.TransactionTable_AccountName}
                label={'Account Name'}
                events={bsProps}
                field={transactionTable?.accountName}
                className={bsProps.className}
                readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.TransactionTable_ABN}
                label={'ABN'}
                events={bsProps}
                field={transactionTable?.abn}
                className={bsProps.className}
                readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
              id={FieldAndColumnName.BankStatementEditor_BSB}
              label={'BSB'}
              events={bsProps}
              field={transactionTable?.bsb}
              className={bsProps.className}
              readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.BankStatementEditor_AccountNumber}
                label={'Account Number'}
                events={bsProps}
                field={transactionTable?.accountNumber}
                className={bsProps.className}
                readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
              id={FieldAndColumnName.BankStatementEditor_StartDate}
              label={'Start Date'}
              events={bsProps}
              field={transactionTable?.startDate}
              className={bsProps.className}
              readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
              id={FieldAndColumnName.BankStatementEditor_EndDate}
              label={'End Date'}
              events={bsProps}
              field={transactionTable?.endDate}
              className={bsProps.className}
              readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDollarField
                id={FieldAndColumnName.TransactionTable_OpeningBalance}
                label={'Opening Balance'}
                events={bsProps}
                field={transactionTable?.openingBalance}
                className={bsProps.className}
                readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDollarField
                id={FieldAndColumnName.TransactionTable_ClosingBalance}
                label={'Closing Balance'}
                events={bsProps}
                field={transactionTable?.closingBalance}
                className={bsProps.className}
                readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDollarField
                id={FieldAndColumnName.TransactionTable_TotalCredit}
                label={'Total Credit'}
                events={bsProps}
                field={transactionTable?.totalCredits}
                className={bsProps.className}
                readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDollarField
                id={FieldAndColumnName.TransactionTable_TotalDebit}
                label={'Total Debit'}
                events={bsProps}
                field={transactionTable?.totalDebits}
                className={bsProps.className}
                readonlyMode={bsProps.readonlyMode}
            />
          </Grid>
          <Grid item xs={12}>
            <TransactionTableEditor
              transactions={transactionTable?.transactions}
              onCellClicked={onCellClicked}
              tableReadOnly={bsProps.tableReadOnly}
              onGridValueChanged={(gridColumnName, rowIndex, value) => {
                bsProps.onGridValueChanged?.(gridColumnName, rowIndex, value)
              }}
            ></TransactionTableEditor>
          </Grid>
        </Grid>
      </form>
    </FormControlContext.Provider>
  )
}
