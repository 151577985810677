// @ts-ignore

import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { FormStringField } from 'components/FormStringField'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import React, { FC, useMemo } from 'react'
import { DividendStatement } from '../../domain/businessModel/dividendstatement/DividendStatement'
import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormDollarField } from '../FormDollarField'

interface IDividendStatementProps extends EditorFieldEvents {
    dividendStatement: DividendStatement
    className?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      fontWeight: 600
    },
    helperText: {
        fontSize: '0.8rem',
        fontStyle: 'italic',
        color: '#256C8F',
        fontWeight: 600
    },
    dividendGrossNet: {
        '& label': {
          fontWeight: 600,
          color: 'black',
          fontSize: '1.1rem'
      }
    }
  })
)

export const DividendStatementEditor: FC<IDividendStatementProps> = (props: IDividendStatementProps) => {
    const styles = useStyles()
    const dividendStatement = props.dividendStatement

    return (
        <form noValidate autoComplete="no">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormDateField
                        id={FieldAndColumnName.DividendStatementEditor_PaymentDate}
                        label={'Payment Date'}
                        events={props}
                        field={dividendStatement.paymentDate}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.DividendStatementEditor_ShareCode}
                        label={'Security Code'}
                        events={props}
                        field={dividendStatement.shareCode}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography className={styles.typography}>Cash Dividend</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={styles.typography}>Tax Paid / Offset</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_DividendFranked}
                        label={'Dividend - Franked'}
                        events={props}
                        field={dividendStatement.dividendFranked}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_FrankingCredits}
                        label={'Franking Credits'}
                        events={props}
                        field={dividendStatement.frankingCredits}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_DividendUnfranked}
                        label={'Dividend - Unfranked'}
                        events={props}
                        field={dividendStatement.dividendUnfranked}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_FrankingCreditsFromNZ}
                        label={'AU Credits From a NZ Company'}
                        events={props}
                        field={dividendStatement.frankingCreditsFromNZ}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_AssessableForeignIncome}
                        label={'Assessable Foreign Source Income'}
                        events={props}
                        field={dividendStatement.assessableForeignIncome}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_ForeignIncomeTaxOffset}
                        label={'Foreign Income Tax Offset'}
                        events={props}
                        field={dividendStatement.foreignIncomeTaxOffset}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                      id={FieldAndColumnName.DividendStatementEditor_GrossPayment}
                      label={'Gross Payment'}
                      events={props}
                      field={dividendStatement.grossDividend}
                      className={clsx([styles.dividendGrossNet, props.className])}
                    />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_WithholdingTax}
                        label={'TFN Amounts Withheld'}
                        events={props}
                        field={dividendStatement.withholdingTax}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_NetPayment}
                        label={'Net Payment'}
                        events={props}
                        field={dividendStatement.netDividend}
                        className={clsx([styles.dividendGrossNet, props.className])}
                    />
                </Grid>
                <Grid item xs={6}>
                        <FormDollarField
                          id={FieldAndColumnName.DividendStatementEditor_ConvertedNetDividend}
                          label={'Converted AU Net Amount'}
                          events={props}
                          field={dividendStatement.convertedNetDividend}
                          className={clsx([styles.dividendGrossNet, props.className])}
                          helperText ={'For Foreign Dividend Only'}
                        />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_SMSFLIC}
                        label={'LIC Deduction'}
                        events={props}
                        field={dividendStatement.SMSFLIC}
                        className={props.className}
                        helperText ={'Superannuation'}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.DividendStatementEditor_IndividualTrustLIC}
                        label={'LIC Deduction'}
                        events={props}
                        field={dividendStatement.IndividualTrustLIC}
                        className={props.className}
                        helperText ={'Individual, Trust or Partnership'}
                    />
                </Grid>
            </Grid>
        </form>
    )
}
