import {
  IBusinessModel,
  IObjectAnnotations,
  IPageAnnotations,
  ISmartDocsResult,
  IWordAnnotation,
  IWordText
} from '@bgl/textract-business-model-editor'
import axios, { AxiosError } from 'axios'
import _ from 'lodash'
import moment from 'moment'

import { apiPath } from '../ApiPath'
import { SUBJECT } from '../components/contactUs/ContactUs'
import {
  ExecutionId,
  ExecutionState,
  ExportCSVBody
} from '../components/others/ExportButtonGroup'
import { onBoardingRequestData } from '../components/SignUpForm'
import { AggAllFilterTypes, Aggregation, Filters } from '../models/Aggregations'
import Business, {
  BusinessDTO,
  Businesses,
  BusinessUsage,
  BusinessUsageDTO
} from '../models/Business'
import { TrainReportsDTO } from '../models/jobReport/TrainingReport'
import Project, { ICheckUniqueAlias, ProjectDTO } from '../models/Project'
import { ProjectType } from '../models/ProjectType'
import { AddTagBody, EditTagBody, Tag, TagDTO, Tags } from '../models/Tag'
import {
  FileImageUrls,
  TFile,
  TFileDTO,
  TFiles,
  WorkflowStatus
} from '../models/TFile'
import { TJobDTO, TJobs, TrainingModelSize } from '../models/TJob'
import { TModelDTO, TModels } from '../models/TModel'
import { jobReportAdapter } from '../utils/adapter/jobReportAdapter'
import { IWorkflowStatusDTO } from '../utils/types'
import BaseApi from './BaseApi'
import { trackEvent } from './GoogleAnalytics'

class TaggingApi extends BaseApi {
  apiPath: string
  private static instance: TaggingApi
  private constructor(apiPath: string) {
    super()
    this.apiPath = apiPath
  }

  async onBoarding(requestData: onBoardingRequestData) {
    try {
      return await this.loadByPost(`${this.apiPath}/onboarding`, requestData)
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'Onboarding',
        label: `${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  getWorkflow(projectId: string) {
    return (workflowKey: string): Promise<IWorkflowStatusDTO | undefined> =>
      this.loadByGet(
        `${this.apiPath}/workflows/${workflowKey}` + `?projectId=${projectId}`
      ).catch((error: string | AxiosError) => {
        trackEvent({
          category: 'ApiError',
          action: 'GetWorkflowStatus',
          label: `${projectId}_${this.mapError(error)}`
        })
        return undefined
      })
  }

  static getInstance(): TaggingApi {
    if (!TaggingApi.instance) {
      TaggingApi.instance = new TaggingApi(apiPath)
    }
    return TaggingApi.instance
  }

  async getBusinesses(): Promise<Businesses> {
    const response = await this.loadByGet(`${this.apiPath}/businesses`)
    return Businesses.fromJson(response.businesses ?? [])
  }

  async getBusiness(
    businessId: string
  ): Promise<{ business?: Business; projects?: Project[] }> {
    const businessDTO: BusinessDTO = await this.loadByGet(
      `${this.apiPath}/businesses/${businessId}`
    )
    const business = businessDTO && Business.fromJson(businessDTO)
    const projects = businessDTO?.projects?.map((projectDTO) =>
      Project.fromJson(projectDTO)
    )
    return { business, projects }
  }

  async getBusinessUsage(
    businessId: string,
    from?: string,
    to?: string
  ): Promise<BusinessUsage[]> {
    const response: BusinessUsageDTO = await this.loadByPost(
      `${this.apiPath}/businesses/${businessId}/usage`,
      { from, to }
    )
    return response.usages ?? []
  }

  async addBusiness(business: Business): Promise<Business | undefined> {
    try {
      const response: BusinessDTO = await this.loadByPost(
        `${this.apiPath}/businesses`,
        business.toBusinessDTO()
      )
      return response.id ? business.setId(response.id) : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'RegisterBusiness',
        label: `${business.name}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async updateBusiness(business: Business): Promise<Business | undefined> {
    try {
      const businessDTO: BusinessDTO = await this.loadByPatch(
        `${this.apiPath}/businesses/${business.id}`,
        _.omit(business.toBusinessDTO(), 'id')
      )
      return businessDTO && Business.fromJson(businessDTO)
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'UpdateBusiness',
        label: `${business.id}_${this.mapError(error)}`
      })
      return undefined
    }
  }

  async getProject(projectId: string): Promise<Project | undefined> {
    try {
      const projectDTO: ProjectDTO = await this.loadByGet(
        `${this.apiPath}/projects/${projectId}`
      )
      return projectDTO ? Project.fromJson(projectDTO) : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'GetProject',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async addProject(
    businessId: string,
    project: Project
  ): Promise<Project | undefined> {
    try {
      const projectDTO: ProjectDTO = await this.loadByPost(
        `${this.apiPath}/projects?businessId=${businessId}`,
        project.toAddProjectDTO()
      )
      return projectDTO ? Project.fromJson(projectDTO) : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'AddProject',
        label: `${project.name}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async updateProject(project: Project): Promise<Project | undefined> {
    const body = project.toUpdateProjectDTO()
    try {
      const projectDTO: ProjectDTO = await this.loadByPatch(
        `${this.apiPath}/projects/${project.id}`,
        body
      )
      return projectDTO ? Project.fromJson(projectDTO) : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'UpdateProject',
        label: `${project.name}_${this.mapError(error)}`
      })
      throw this.mapError(error)
    }
  }

  async checkUniqueAlias(project: Project): Promise<ICheckUniqueAlias> {
    try {
      const results: ICheckUniqueAlias = await this.loadByPatch(
        `${this.apiPath}/projects/${project.id}/dry-run`,
        project.toUpdateProjectDTO()
      )
      return results ?? { ok: false, message: '' }
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'dry-run',
        label: `${project.name}_${this.mapError(error)}`
      })
      return { ok: false, message: '' }
    }
  }

  async syncProject(projectId: string): Promise<{ ok: boolean } | undefined> {
    try {
      const result: { ok: boolean } = await this.loadByPatch(
        `${this.apiPath}/projects/${projectId}/sync`,
        undefined
      )
      return result ? result : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'SyncProject',
        label: `${projectId}_${this.mapError(error)}`
      })
      return undefined
    }
  }

  async deleteProject(projectId: string): Promise<void> {
    try {
      return this.loadByDelete(`${this.apiPath}/projects/${projectId}`)
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'DeleteProject',
        label: `${projectId}_${this.mapError(error)}`
      })
    }
  }

  async startExportExecution(
    projectId: string,
    exportType: string,
    requestBody: ExportCSVBody
  ): Promise<ExecutionId> {
    try {
      const exportExecutionId: ExecutionId = await this.loadByPost(
        `${this.apiPath}/projects/${projectId}/export/${exportType}`,
        requestBody
      )
      return exportExecutionId
        ? exportExecutionId
        : Promise.reject('No execution id')
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'ExportProjectResultCsv',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async queryExecutionState(
    projectId: string,
    executionId: string
  ): Promise<ExecutionState> {
    try {
      const executionState: ExecutionState = await this.loadByGet(
        `${this.apiPath}/projects/${projectId}/export/${executionId}/state`
      )
      return executionState
        ? executionState
        : Promise.reject('No execution state')
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'ExportProjectExecutionState',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async listTags(project: Project): Promise<Tags> {
    const path = project.isCustomerTrainedType()
      ? `${this.apiPath}/tags` + `?projectId=${project.id}`
      : `${this.apiPath}/tags/pretrained/${project.projectType}` +
        `?projectId=${project.id}`
    try {
      const { tags }: { tags: TagDTO[] } = await this.loadByGet(path)
      return Tags.fromJson(tags)
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'ListTags',
        label: `${project.id}_${this.mapError(error)}`
      })
      return new Tags()
    }
  }

  async addTag(
    addTagBody: AddTagBody,
    projectId: string
  ): Promise<Tag | undefined> {
    try {
      const tag: TagDTO = await this.loadByPost(
        `${this.apiPath}/tags` + `?projectId=${projectId}`,
        addTagBody
      )
      return tag ? Tag.fromJson(tag) : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'AddTag',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async updateTag(
    id: string,
    editTagBody: EditTagBody,
    isHidden: boolean,
    projectId: string
  ): Promise<undefined> {
    try {
      return this.loadByPatch(
        `${this.apiPath}/tags/${id}` + `?projectId=${projectId}`,
        { ...editTagBody, isHidden }
      )
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'UpdateTag',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async uploadFiles(
    files: File[],
    businessId: string | null,
    projectId: string,
    isTraining: boolean,
    uploadUser: string | undefined,
    modelId: string | undefined,
    onProgress: (percentComplete: number) => void
  ) {
    const formData = new FormData()
    if (!_.isNil(modelId)) formData.append('modelId', modelId)
    files.forEach((file) => formData.append('user_file', file, file.name))
    return this.uploadFileFormData(
      `${this.apiPath}/files?isTraining=${isTraining ? 'true' : 'false'}` +
        `&isAsync=true&uploadUser=${uploadUser}&uploadSource=TaggingUI&analyticalGroup1=${businessId}&analyticalGroup2=${projectId}` +
        `&projectId=${projectId}`,
      formData,
      onProgress
    ).then((response) => response)
  }

  async listFiles(
    projectId: string,
    isTraining: boolean,
    workflowStatus?: WorkflowStatus,
    startDateTime?: moment.Moment,
    endDateTime?: moment.Moment
  ): Promise<TFiles> {
    return this.loadByGet(
      `${this.apiPath}/files?isTraining=${isTraining}` +
        (workflowStatus ? `&workflowStatus=${workflowStatus}` : '') +
        (startDateTime ? `&startDateTime=${startDateTime.toISOString()}` : '') +
        (endDateTime ? `&endDateTime=${endDateTime.toISOString()}` : '') +
        `?projectId=${projectId}`
    )
      .then(({ files }: { files: TFileDTO[] }) => TFiles.fromJson(files))
      .catch((error: string | AxiosError) => {
        trackEvent({
          category: 'ApiError',
          action: 'ListTrainingFiles',
          label: `${projectId}_${this.mapError(error)}`
        })
        return new TFiles()
      })
  }

  async searchFiles(
    projectId: string,
    userInput?: string,
    from?: number,
    size?: number,
    filters?: Filters | [],
    aggregations: AggAllFilterTypes[] = [],
    neighbor?: { fileId: string; kNearest?: number }
  ) {
    return this.loadByPost(
      `${this.apiPath}/files/search` + `?projectId=${projectId}`,
      { userInput, from, size, filters, aggregations, neighbor }
    )
      .then((res) => {
        const files = TFiles.fromJson(res.files)
        const total = res.total
        const aggregations = res.aggregations
          ? Aggregation.fromJson(res.aggregations)
          : new Aggregation()
        return {
          files: files,
          total: total,
          aggregations: aggregations
        }
      })
      .catch((error: string | AxiosError) => {
        trackEvent({
          category: 'ApiError',
          action: 'SearchFiles',
          label: `${projectId}_${this.mapError(error)}`
        })
        return {
          files: new TFiles(),
          total: null,
          aggregations: new Aggregation()
        }
      })
  }

  async projectFiles(
    projectId: string,
    userInput?: string,
    filters?: Filters | []
  ): Promise<string | undefined> {
    return this.loadByPost(
      `${this.apiPath}/files/projector` + `?projectId=${projectId}`,
      { userInput, filters }
    )
      .then(({ url }: { url: string | undefined }) => url)
      .catch((error: string | AxiosError) => {
        trackEvent({
          category: 'ApiError',
          action: 'ProjectFiles',
          label: `${projectId}_${this.mapError(error)}`
        })
        return undefined
      })
  }

  async getFile(fileId: string, projectId: string): Promise<TFile | undefined> {
    try {
      const file: TFileDTO = await this.loadByGet(
        `${this.apiPath}/files/${fileId}` + `?projectId=${projectId}`
      )
      return file ? TFile.fromJson(file) : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'GetTrainingFile',
        label: `${projectId}_${this.mapError(error)}`
      })
      return undefined
    }
  }

  async getFileImages(
    fileId: string,
    projectId: string,
    abortController: AbortController
  ): Promise<FileImageUrls | undefined> {
    try {
      const fileImageUrls: FileImageUrls = await this.loadByGet(
        `${this.apiPath}/files/${fileId}/images` + `?projectId=${projectId}`,
        undefined,
        undefined,
        undefined,
        abortController.signal
      )
      return fileImageUrls ? fileImageUrls : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'GetFileImageUrls',
        label: `${projectId}_${this.mapError(error)}`
      })
      return undefined
    }
  }

  async updateFile(
    fileId: string,
    projectId: string,
    { filename, isRead, labelIds }: Partial<TFile>
  ): Promise<TFileDTO | undefined> {
    try {
      return this.loadByPatch(
        `${this.apiPath}/files/${fileId}` + `?projectId=${projectId}`,
        { filename, isRead, labelIds }
      )
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'UpdateTrainingFile',
        label: `${projectId}_${this.mapError(error)}`
      })
      return undefined
    }
  }

  async repredictFile(fileId: string, projectId: string): Promise<void> {
    try {
      return await this.loadByPost(
        `${this.apiPath}/files/${fileId}/repredict` + `?projectId=${projectId}`,
        {}
      )
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'RepredictFile',
        label: `${projectId}_${this.mapError(error)}`
      })
      return undefined
    }
  }

  async deleteFile(
    fileId: string,
    projectId: string,
    isTraining: boolean
  ): Promise<void> {
    try {
      return this.loadByDelete(
        `${this.apiPath}/files/${fileId}` +
          `?projectId=${projectId}` +
          `&isTraining=${isTraining}`
      )
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'DeleteTrainingFile',
        label: `${projectId}_${this.mapError(error)}`
      })
    }
  }

  async listTrainingJobs(
    projectId: string
  ): Promise<{ jobs: TJobs; models: TModels }> {
    try {
      const { jobs, models }: { jobs: TJobDTO[]; models: TModelDTO[] } =
        await this.loadByGet(
          `${this.apiPath}/training/jobs` + `?projectId=${projectId}`
        )
      return {
        jobs: TJobs.fromJson(jobs),
        models: TModels.fromJson(models)
      }
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'ListTrainingJobs',
        label: `${projectId}_${this.mapError(error)}`
      })
      return { jobs: new TJobs(), models: new TModels() }
    }
  }

  async startTrainingJob(
    projectId: string,
    name?: string,
    modelSize?: TrainingModelSize
  ): Promise<void> {
    try {
      return this.loadByPost(
        `${this.apiPath}/training/jobs` + `?projectId=${projectId}`,
        { name, modelSize }
      )
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'StartTrainingJob',
        label: `${projectId}_${this.mapError(error)}`
      })
    }
  }

  async deleteTrainingJob(jobId: string, projectId: string): Promise<void> {
    try {
      return await this.loadByDelete(
        `${this.apiPath}/training/jobs/${jobId}` + `?projectId=${projectId}`
      )
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'DeleteTrainingJob',
        label: `${projectId}_${this.mapError(error)}`
      })
    }
  }

  async getTrainingJobReport(
    jobId: string,
    projectId: string,
    projectType: ProjectType
  ) {
    try {
      const response: { reports: TrainReportsDTO } = await this.loadByGet(
        `${this.apiPath}/training/jobs/${jobId}/reports` +
          `?projectId=${projectId}`
      )
      return response ? jobReportAdapter(response, projectType) : undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'GetTrainingJobReport',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async getFilePdfUrl(
    fileId: string,
    projectId: string,
    isTraining: boolean
  ): Promise<string | null> {
    try {
      const { url: urlString }: { url: string } = await this.loadByGet(
        `${this.apiPath}/files/${fileId}/file` +
          `?projectId=${projectId}` +
          `&isTraining=${isTraining}`
      )
      return urlString
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'GetFilePdfUrl',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async getFilePdfBlob(
    fileId: string,
    projectId: string,
    abortController: AbortController
  ): Promise<Blob | null> {
    try {
      const { url: urlString }: { url: string } = await this.loadByGet(
        `${this.apiPath}/files/${fileId}/file` + `?projectId=${projectId}`
      )
      return await this.loadByBlob(urlString, abortController.signal)
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'GetFilePdfBlob',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async getFileResult(
    fileId: string,
    projectId: string,
    abortController: AbortController
  ): Promise<ISmartDocsResult | null> {
    try {
      const { url: urlString }: { url: string } = await this.loadByGet(
        `${this.apiPath}/files/${fileId}/result` + `?projectId=${projectId}`,
        undefined,
        undefined,
        undefined,
        abortController.signal
      )
      return await this.loadByGet(
        urlString,
        undefined,
        'json',
        false,
        abortController.signal
      )
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'GetFileResult',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw new Error(this.mapError(error))
    }
  }

  async updateFileResult(
    projectId: string,
    updates: {
      FileId: string
      BusinessModels?: IBusinessModel[]
      WordAnnotations?: IWordAnnotation[]
      WordTexts?: IWordText[]
      PageAnnotations?: IPageAnnotations[]
      ObjectAnnotations?: IObjectAnnotations[]
    }
  ): Promise<ISmartDocsResult | undefined> {
    try {
      const result = await this.loadByPatch(
        `${this.apiPath}/files/${updates.FileId}/result` +
          `?projectId=${projectId}`,
        _.omit(updates, 'FileId')
      )
      return result ?? undefined
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'UpdateFileResult',
        label: `${projectId}_${this.mapError(error)}`
      })
      return undefined
    }
  }

  async getRedactedFile(
    fileId: string,
    projectId: string,
    downloadFileName: string,
    style: string,
    fields: string[]
  ): Promise<Blob> {
    try {
      return axios({
        url:
          `${this.apiPath}/files/${fileId}/redact/${downloadFileName}` +
          `?projectId=${projectId}` +
          `&style=${style}` +
          `&fields=${fields.join(',')}`,
        method: 'GET',
        responseType: 'blob',
        withCredentials: true,
        headers: {
          Accept: 'application/pdf' // Explicitly accept PDF Blob
        }
      }).then((response) => response.data)
    } catch (error) {
      trackEvent({
        category: 'ApiError',
        action: 'getRedactedFile',
        label: `${projectId}_${this.mapError(error)}`
      })
      throw error
    }
  }

  createTicket(
    name: string,
    email: string,
    subject: SUBJECT,
    message: string,
    business?: string
  ): Promise<void> {
    return this.loadByPost(
      'https://smartdocs-builder.zendesk.com/api/v2/requests.json',
      {
        request: {
          requester: { name, email },
          subject: `SmartDocs_${subject}`,
          comment: { body: message },
          custom_fields: [{ id: 360004318375, value: business }]
        }
      },
      undefined,
      false
    )
  }
}

export default TaggingApi
