import { IAnnotatorAnnotation, Point, shapeFactory } from '@bgl/smartdoc-image-annotator'
import { CreateType } from '@bgl/smartdoc-image-annotator'
import { Map } from 'immutable'
import { ObjectAnnotation, ObjectAnnotationsOnFile } from '../../domain/ObjectAnnotation'
import { rgbToRgba } from '../../utils/rgbToRgba'

export const objectAnnotationBuilder = (
  objectAnnotations: ObjectAnnotationsOnFile,
  colorMap: Map<string, string>,
  tagIdToNameMap: Map<string, string>,
  hideLabels: string[],
  selectedAnnotation?: ObjectAnnotation): IAnnotatorAnnotation[] => objectAnnotations.objectAnnotations.toArray().map(objectAnnotation => {
    const shapes = objectAnnotation.annotations.toArray()
      .filter(annotation => !hideLabels.includes(annotation.tagId))
      .map((annotation, index) => {
        const x0 = annotation.objectAnnotationCoor.xmin
        const y0 = annotation.objectAnnotationCoor.ymin
        const x1 = annotation.objectAnnotationCoor.xmax
        const y1 = annotation.objectAnnotationCoor.ymax
        const coor = [[x0, y0], [x1, y1]] as Point[]

        const color = colorMap.get(annotation.tagId) || 'rgb(0,0,0)'
        const drawStyle = {
          strokeStyle: color,
          fillStyle: rgbToRgba(color, 0.1),
          lineWidth: 2
        }
        const tagName = tagIdToNameMap.get(annotation.tagId) || annotation.tagId
        const createType = (x0 === x1 && y0 === y1) ? CreateType.Dot : CreateType.Rect
        const shape = shapeFactory(createType, annotation.objectId, index, coor, drawStyle, tagName, annotation.tagId)
        shape.isActive = selectedAnnotation?.objectId === shape.shapeId
        return shape
    })

    return {
      shapes,
      pageId: objectAnnotation.pageId
    }
  })
