import { pdfjs, PdfMode, SmartDocPDFViewerMobile } from '@bgl/textract-doc-viewer'
import autobind from 'autobind-decorator'
import { List, OrderedMap } from 'immutable'
import memoize from 'memoize-one'
import React from 'react'
import { OcrDocument } from '../../domain/OcrDocument'
import { adapterFromDocument } from './IOverlayPageTransform'
import { wordsOverlay } from './OverlayFactory'

interface MobileDocViewerProps {
  pdfBlob: Blob,
  fileName: string,
  document?: OcrDocument
  onDocumentReady?: (pdf: pdfjs.PDFDocumentProxy) => void
  annotationMap?: OrderedMap<string, List<string>>
  viewMode?: 'wordTagging' | 'pageTagging'
  scale?: number
  pdfMode?: PdfMode.MobileScrollMode | PdfMode.MobileSinglePageMode
  onPasswordCancel?: () => void
}

@autobind
export default class MobileDocViewer extends React.Component<MobileDocViewerProps> {
  constructor(props: MobileDocViewerProps) {
    super(props)
  }

  private pdfViewer: SmartDocPDFViewerMobile | null = null

  scrollIntoView(overlayId?: string) {
    if (this.pdfViewer) {
      this.pdfViewer.scrollIntoView(overlayId)
    }
  }

  goToPage(pageNumber: number) {
    if (this.pdfViewer) {
      this.pdfViewer.goToPage(pageNumber)
    }
  }

  prevPage() {
    if (this.pdfViewer) {
     return this.pdfViewer.prevPage()
    }
  }

  nextPage() {
    if (this.pdfViewer) {
     return this.pdfViewer.nextPage()
    }
  }


  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
  private readonly buildOverlay = memoize((document: OcrDocument) => this.props.annotationMap && wordsOverlay(document, this.props.annotationMap))

  render() {
    const overlays = this.props.document && this.buildOverlay(this.props.document)
    const pageTransforms = this.props.document && adapterFromDocument(this.props.document)
    const viewMode = this.props.viewMode || 'wordTagging'
    return (
      <SmartDocPDFViewerMobile
        ref={ref => {
          this.pdfViewer = ref
        }}
        pdfBlob={this.props.pdfBlob}
        fileName={this.props.fileName}
        overlays={overlays}
        pageTransforms={pageTransforms}
        onDocumentReady={this.props.onDocumentReady}
        viewMode={viewMode}
        scale={this.props.scale}
        pdfMode={this.props.pdfMode || PdfMode.MobileScrollMode}
        onPasswordCancel={this.props.onPasswordCancel}
      />
    )
  }
}
