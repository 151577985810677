import autoBind from 'autobind-decorator'
import {OcrDocument} from '../../OcrDocument'
import {
    IASICFeeModel,
    IBusinessModel,
    IModelType
} from '../../types/ISmartDocsResult'
import {BusinessModelMapper} from '../BusinessModelMapper'
import {ASICFee} from './ASICFee'


@autoBind
export class ASICFeeMapper extends BusinessModelMapper {
    constructor(
        document:OcrDocument,
        private readonly jsonModel: IASICFeeModel
    ){
        super(document)
    }

    fromJson(): ASICFee {
        const ASICFeeModel = this.jsonModel
        const model = new ASICFee(
            IModelType.ASICFee,
            ASICFeeModel.Version,
            this.mapDoubleBlock(ASICFeeModel.TotalDue),
            this.mapTextBlock(ASICFeeModel.CompanyName),
            this.mapDateBlock(ASICFeeModel.IssueDate),
            this.mapDateBlock(ASICFeeModel.DueDate),
            this.mapTextBlock(ASICFeeModel.ACN),
            this.mapTextBlock(ASICFeeModel.CompanyRegisteredAddress),
            this.mapTextBlocks(ASICFeeModel.CompanyOfficeholder),
            this.mapDateBlocks(ASICFeeModel.CompanyOfficeholderDOB),
            this.mapTextBlocks(ASICFeeModel.CompanyMember)
        )
        return model
    }

    static toJson(model: ASICFee): IBusinessModel {
        return {
            ModelType: IModelType.ASICFee,
            Version: model.version,
            TotalDue:model.totalDue?.toModelKeyValue(),
            CompanyName:model.companyName?.toModelKeyValue(),
            IssueDate: model.issueDate?.toModelKeyValue(),
            DueDate: model.dueDate?.toModelKeyValue(),
            ACN: model.acn?.toModelKeyValue(),
            CompanyRegisteredAddress:model.companyRegisteredAddress?.toModelKeyValue(),
            CompanyOfficeholder:model.companyOfficeholder?.map(it=>it.toModelKeyValue()),
            CompanyOfficeholderDOB:model.companyOfficeholderDOB?.map(it=>it.toModelKeyValue()),
            CompanyMember:model.companyMember?.map(it=>it.toModelKeyValue())
        } as IASICFeeModel
    }
}
