import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import React, { FC } from 'react'
import {ASICFee} from '../../domain/businessModel/asicFee/ASICFee'
import { EditorFieldEvents } from '../EditorFieldEvents'
import {FormDateFieldArray} from '../FormDateFieldArray'
import {FormDollarField} from '../FormDollarField'
import { FormStringField } from '../FormStringField'
import {FormStringFieldArray} from '../FormStringFieldArray'
import {FormDateField} from "../FormDateField";

interface IASICFeeProps extends EditorFieldEvents {
    asicFee:ASICFee
    className?: string
}
const useStyles = makeStyles(() =>
    createStyles({
        typography: {
            fontWeight: 600
        },
        totalDue: {
            '& label': {
                fontWeight: 600,
                color: 'black',
                fontSize: '1.2rem'
            }
        }
    })
)

export const ASICFeeEditor: FC<IASICFeeProps> = (props: IASICFeeProps) =>{
    const styles = useStyles()
    const asicFee = props.asicFee
    return (
        <form noValidate autoComplete="no">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.ASICFeeEditor_CompanyName}
                        label={'Company Name'}
                        events={props}
                        field={asicFee.companyName}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.ASICFeeEditor_ACN}
                        label={'ACN'}
                        events={props}
                        field={asicFee.acn}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormStringField
                        id={FieldAndColumnName.ASICFeeEditor_CompanyRegisteredAddress}
                        label={'Company Registered Address'}
                        events={props}
                        field={asicFee.companyRegisteredAddress}
                        className={props.className}
                    />
                </Grid>
                {props.asicFee.companyOfficeholder &&
                    <Grid item xs={6}>
                        <FormStringFieldArray
                            id={FieldAndColumnName.ASICFeeEditor_CompanyOfficeholder}
                            label={'Company Officeholders'}
                            events={props}
                            fields={props.asicFee.companyOfficeholder}
                            className={props.className}
                        />
                    </Grid>}
                {props.asicFee.companyOfficeholderDOB &&
                    <Grid item xs={6}>
                        <FormDateFieldArray
                            id={FieldAndColumnName.ASICFeeEditor_CompanyOfficeholderDOB}
                            label={'Company Officeholders\' DOB'}
                            events={props}
                            fields={props.asicFee.companyOfficeholderDOB}
                            className={props.className}
                        />
                    </Grid>}
                {props.asicFee.companyMember &&
                    <Grid item xs={6}>
                        <FormStringFieldArray
                            id={FieldAndColumnName.ASICFeeEditor_CompanyMember}
                            label={'Company Members'}
                            events={props}
                            fields={props.asicFee.companyMember}
                            className={props.className}
                        />
                    </Grid>}
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <FormDateField
                        id={FieldAndColumnName.ASICFeeEditor_IssueDate}
                        label={'Issue Date'}
                        events={props}
                        field={props.asicFee.issueDate}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDateField
                        id={FieldAndColumnName.ASICFeeEditor_DueDate}
                        label={'Due Date'}
                        events={props}
                        field={props.asicFee.dueDate}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDollarField
                        id={FieldAndColumnName.ASICFeeEditor_TotalDue}
                        label={'Total Amount Due'}
                        events={props}
                        field={asicFee.totalDue}
                        className={clsx([styles.totalDue, props.className])}
                    />
                </Grid>
            </Grid>
        </form>
    )
}
