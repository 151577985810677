import { GridCellParams, GridCellValue, GridColDef, GridRowsProp, GridValueGetterParams } from '@material-ui/data-grid'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { List } from 'immutable'
import moment from 'moment'
import React from 'react'
import { DetectedField } from '../DetectedField'
import ModelObject from '../ModelObject'
import { AtoActivityStatementTransaction, AtoActivityStatementTransactionColumn } from './AtoActivityStatementTransaction'

export class AtoActivityStatementTransactions extends ModelObject {
  constructor(
    readonly atoActivityTransactions: List<AtoActivityStatementTransaction>
  ) {
    super()
  }

  transactionByIndex(index: number): AtoActivityStatementTransaction | undefined {
    return this.atoActivityTransactions.get(index)
  }

  listFields(): List<DetectedField> {
    return this.atoActivityTransactions.reduce((combined, atoActivityTransaction) => {
      return combined.concat(atoActivityTransaction.listFields())
    }, List<DetectedField>())
  }

  static getCredit(params: GridValueGetterParams): GridCellValue {
    if (params.value) {
      return Math.abs(params.value as number)
    } else {
      return undefined
    }
  }

  fieldByColRow(columnName: AtoActivityStatementTransactionColumn, rowIndex: number): DetectedField | undefined {
    const transaction = this.transactionByIndex(rowIndex)
    if (transaction) {
      return transaction.fieldByColumn(columnName)
    } else {
      return undefined
    }
  }

  static toGridColDef(renderCell: (params: GridCellParams) => React.ReactNode): GridColDef[] {
    return [
      {
        field: FieldAndColumnName.AtoActivityStatementTransactionTable_ProcessedDate,
        type: 'date',
        headerName: 'PROCESSED DATE',
        description: 'Processed Date',
        width: 100,
        editable: true,
        renderCell: (params: GridCellParams) => renderCell(params)

      },
      {
        field: FieldAndColumnName.AtoActivityStatementTransactionTable_EffectiveDate,
        type: 'date',
        headerName: 'EFFECTIVE DATE',
        description: 'Effective Date',
        width: 100,
        editable: true,
        renderCell: (params: GridCellParams) => renderCell(params)
      },
      {
        field: FieldAndColumnName.TransactionTable_Description,
        headerName: 'DESCRIPTION',
        width: 350,
        editable: true
      },
      {
        field: FieldAndColumnName.TransactionTable_TotalDebit,
        type: 'number',
        headerName: 'DEBIT',
        width: 120,
        editable: true
      },
      {
        field: FieldAndColumnName.TransactionTable_TotalCredit,
        type: 'number',
        headerName: 'CREDIT',
        width: 120,
        valueGetter: this.getCredit,
        editable: true
      },
      {
        field: FieldAndColumnName.TransactionTable_OpeningBalance,
        type: 'number',
        headerName: 'BALANCE',
        width: 120,
        hide: false,
        editable: true
      }
    ]
  }

  toGridRowsProp(): GridRowsProp {
    return this.atoActivityTransactions.map((item, index) => {
      return item.toGridRow(index)
    }).toArray()
  }

  blockIds(): List<string> {
    return this.atoActivityTransactions.flatMap(t => t.blockIds())
  }

  totalCredits(): number {
    return this.reduce((sum, trans) => {
      return trans.amount && trans.amount.parsedValue < 0 ? sum - trans.amount.parsedValue : sum
    }, 0.0)
  }

  totalDebits(): number {
    return this.reduce((sum, trans) => {
      return trans.amount && trans.amount.parsedValue > 0 ? sum + trans.amount.parsedValue : sum
    }, 0.0)
  }

  size(): number {
    return this.atoActivityTransactions.size
  }

  reduce<T>(f: (reduction: T, value: AtoActivityStatementTransaction, key: number) => T, init: T): T {
    return this.atoActivityTransactions.reduce(f, init)
  }

  mapToArray<Z>(mapper: (value: AtoActivityStatementTransaction, key: number) => Z): Z[] {
    return this.atoActivityTransactions.map(mapper).valueSeq().toArray()
  }

  reverse(): AtoActivityStatementTransactions {
    return new AtoActivityStatementTransactions(this.atoActivityTransactions.reverse())
  }
}
