import React from "react";
import {
  DataGrid, GridCellParams,
  GridColDef,
  GridEditCellValueParams,
  GridRowsProp,
  GridToolbarContainer, GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@material-ui/data-grid";
import {Invoice} from '../../domain/businessModel/invoice/Invoice'
import {EditorFieldEvents} from "../EditorFieldEvents"
import {DetectedField} from "../../domain/businessModel/DetectedField"
import {InvoiceItem, InvoiceItemColumn} from "../../domain/businessModel/invoice/InvoiceItem"
import { FieldAndColumnName } from "domain/validator/FieldValidatorDef";

interface IInvoiceItemsEditorProp extends EditorFieldEvents {
  invoice?: Invoice
  onCellClick?: (invoice: Invoice, item: InvoiceItem, field: DetectedField) => void
}

export const InvoiceItemsEditor = (itemsProp: IInvoiceItemsEditorProp) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton/>
        <GridToolbarDensitySelector/>
        <GridToolbarExport/>
      </GridToolbarContainer>
    );
  }

  function onCellClick(params: GridCellParams) {
    if (itemsProp.onCellClick && itemsProp.invoice) {
      const columnName = params.field
      const rowId = +params.id
      const invoice = itemsProp.invoice
      const item = invoice.byIndex(rowId)
      const field = item?.fieldByColumn(columnName as InvoiceItemColumn)
      if (item && field) {
        itemsProp.onCellClick(invoice, item, field)
      } else {
        console.error(`not able to field cell based on column ${columnName} and rowId ${rowId}`)
      }
    }
  }


  const columns: GridColDef[] = Invoice.toGridColDef()

  const rows: GridRowsProp = itemsProp.invoice?.toGridRowsProp() ?? []

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar,
      }}
      onCellClick={onCellClick}
      onCellValueChange={
        (params: GridEditCellValueParams) => {
          // handleGridInputValue(params.field, params.id, params.value, record)
          itemsProp.onGridValueChanged?.(params.field as FieldAndColumnName, params.id, params.value)
        }
      }
    />
  )
}
