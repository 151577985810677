import {Chip, Container} from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Checkbox from '@material-ui/core/Checkbox'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {List, Map} from 'immutable'
import * as _ from 'lodash'
import React, {FunctionComponent, useEffect, useState} from 'react'
import { Annotation, AnnotationId, Annotations } from '../../domain/Annotation'

interface IAnnotationViewerProp {
  annotations: Annotations
  annotationMap: Map<string, string>,
  tagColorMap: Map<string, string>,
  pageIdToPageNumber: (id: AnnotationId) => number | undefined,
  onAnnotationClicked?: (annotation: Annotation) => void
  selectedAnnotationKeys: (selectedAnnotationKeys: string[]) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({    root: {

    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    tagColor: {
      height: 20,
      borderRadius: 5,
      width: 30,
      minWidth: 30,
      marginRight: '0.5rem'
    },
    column: {
      flexBasis: '33.33%',
      display: 'flex',
      alignItems: 'center'
    },
    detailsContainer: {
      '& > *': {
        margin: theme.spacing(0.5)
      }
    }
  })
)

export const AnnotationViewer: FunctionComponent<IAnnotationViewerProp> = (props: IAnnotationViewerProp) => {
  const annotations = props.annotations
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(()=>{props.selectedAnnotationKeys(selectedKeys)},[selectedKeys])

  const handleClick = (annotation: Annotation) => {
    if (props.onAnnotationClicked) {
      props.onAnnotationClicked(annotation)
    }
  }

  const handleSelect =(key: string) => {
    return (event:React.ChangeEvent<HTMLInputElement>) => {
      if(event.target.checked){
        setSelectedKeys(prevKeys => [...prevKeys, key])
      } else {
        setSelectedKeys(prevKeys => _.without(prevKeys, key))
      }
    }
  }

  const getName = (id: string): string => props.annotationMap.get(id, id)

  const generateAnnotationPanelId = (key: string) => `annotation-panel-${key}`

  const renderWordAnnotation = (key: string, annotations: List<Annotation>) => {
    const annotationName = getName(key)
    const annotationString = annotations.map(annotation => annotation.viewText(props.pageIdToPageNumber)).join(' ').substr(0, 30)
    return (
      <Accordion key={key} expanded={expanded === generateAnnotationPanelId(key)} onChange={handleChange(generateAnnotationPanelId(key))}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls={`${key}-content`}
          id={`${key}-header`}
        >
          <Checkbox
            onChange={handleSelect(key)}
            onClick={(event) => event.stopPropagation()}
            />
          <p className={classes.tagColor} style={{ backgroundColor: `${props.tagColorMap?.get(key)}`}} />
          <div className={classes.column}>
            <Typography className={classes.heading}>{annotationName}</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>{annotationString}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Container maxWidth="sm" className={classes.detailsContainer}>
            {
              annotations.map(annotation => {
                return (
                  <Chip
                    size="small"
                    label={annotation.viewText(props.pageIdToPageNumber)}
                    id={`annotation-${annotation.id.id}`}
                    key={`annotation-${annotation.id.id}`}
                    onClick={() => handleClick(annotation)}
                  />
                )
              })
            }
          </Container>
        </AccordionDetails>
      </Accordion>
    )
  }


  return (
    <div className={classes.root}>
      {
        annotations.groupByTagId().map((annotations, key) => renderWordAnnotation(key, annotations)).toList()
      }
    </div>
  )
}
