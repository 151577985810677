import { List } from 'immutable'
import _ from 'lodash'
import { OcrDocument } from '../../OcrDocument'
import { IBusinessModelTypeModel, IBusinessModelTypePage } from '../../types/ISmartDocsResult'
import { BusinessModelMapper } from '../BusinessModelMapper'
import { BusinessModelType } from './BusinessModelType'
import { BusinessModelTypePageTable, BusinessModelTypePageTableRow } from './BusinessModelTypePageTable'

export class BusinessModelTypeMapper extends BusinessModelMapper {
  constructor(
    document: OcrDocument,
    private readonly jsonModel: IBusinessModelTypeModel
  ) {
    super(document)
  }

  fromJson(): BusinessModelType {
    const businessModelTypeModel = this.jsonModel
    const businessModelTypePageTable = this.mapTable(businessModelTypeModel.Pages)
    return new BusinessModelType(
      businessModelTypeModel.Version,
      businessModelTypeModel.ModelName,
      businessModelTypeModel.Probability,
      businessModelTypePageTable
    )
  }

  mapPageRow(page: IBusinessModelTypePage): BusinessModelTypePageTableRow | undefined {
    return new BusinessModelTypePageTableRow(
      page.Id,
      page.ModelName,
      page.Probability
    )
  }

  mapTable(pages: IBusinessModelTypePage[]): BusinessModelTypePageTable {
    const rows = _.compact((pages || []).map(this.mapPageRow))
    return new BusinessModelTypePageTable(
      List(rows)
    )
  }
}
