import {TextField} from '@material-ui/core'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { FormFieldValidator } from 'domain/validator/FormFieldValidator'
import { debounce, has, isNumber } from 'lodash'
import React, {FunctionComponent, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import { FormControlContext } from 'utils/context/FormControlContext'
import {DoubleField} from '../domain/businessModel/DetectedField'
import {EditorFieldEvents} from './EditorFieldEvents'
import { mathRound } from 'utils/utils'


export const FormDoubleField: FunctionComponent<{ id: FieldAndColumnName, label: string, events: EditorFieldEvents, field?: DoubleField, className?: string, readonlyMode?: boolean }> =
  ({
    id,
    label,
    events,
    field,
    className,
    readonlyMode
  }) => {
    const {
      // handleInputValue,
      errors
      // record
    } = useContext(FormControlContext)
    const errorKey = useMemo(() => FormFieldValidator.generateErrorKey(id, field?.id || ''), [id, field])
    const update = (value: number) => events.onValueChanged?.(id, value, field)
    const debouncedOnChange = useCallback(debounce(update, 1000), [events.onValueChanged])
      // FIXME: A temp solution to prevent crash, reason is onChange feeding string in event.target.value.
    // Need guaranteed number in DollarField's updateValue()
    const defaultValue = field?.parsedValue !== undefined ? Number(field.parsedValue).toFixed(2) : 'N/A'
    // NOTE: YET I NEED IT TO BE 'number | string'
    const [value, updateValue] = useState<number | string>();

    // NOTE: DO NOT DELETE
    useEffect(() => updateValue(field?.parsedValue?.toFixed(2)), [field?.parsedValue])

  return (
    <TextField
      className={className}
      id={id}
      label={label}
      type="number"
      fullWidth
      placeholder = "N/A"
      InputLabelProps={{ shrink: true }}
      defaultValue={defaultValue}
      value={value ?? ''}
      InputProps={{ inputProps: { step: 0.01 } }}
      onFocus={() => events.onFieldFocus?.(id, field)}
      onChange={(event) => {
        // NOTE: Do not call handleInputValue() to immediately validate new value as onValueChanged() trigger
        // a new instance of model been injected to model editor, and useFormControl() validates all fields.
        // I intend to leave handleInputValue() for later use, redux-form might be used if performance is concern.
        // handleInputValue(id, event.target.value, field?.id || "", record)
        const value = Number(event.target.value)
        if (isNumber(value)) {
          const valueFormatted = mathRound(value)
          debouncedOnChange(valueFormatted)
          updateValue(valueFormatted)
        } else {
          console.warn(`Unhandlable value(${value}) in unknown format has been received.`)
        }
      }}
      error={has(errors, errorKey)}
      helperText={errors[errorKey]}
      disabled={readonlyMode}
    />
  )
}
