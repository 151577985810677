import {
  ContractNote,
  FieldAndColumnName
} from '@bgl/textract-business-model-editor'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { FunctionComponent } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormDollarField } from '../FormDollarField'
import { FormDoubleField } from '../FormDoubleField'
import { FormStringField } from '../FormStringField'

interface ContractNoteProps extends EditorFieldEvents {
  contractNote: ContractNote
  className?: string
  readonlyMode?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    TotalAmount: {
      '& label': {
        fontWeight: 600,
        color: 'black',
        fontSize: '1.2rem'
      }
    }
  })
)

export const ContractNoteEditor: FunctionComponent<ContractNoteProps> = (
  bsProps: ContractNoteProps
) => {
  const contractNote = bsProps.contractNote
  const styles = useStyles()

  return (
    <form noValidate autoComplete="on">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormStringField
            id={FieldAndColumnName.ContractNoteEditor_BrokerName}
            label={'Broker Name'}
            events={bsProps}
            field={contractNote.brokerName}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ContractNoteEditor_ReferenceNumber}
            label={'Reference Number'}
            events={bsProps}
            field={contractNote.referenceNumber}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ContractNoteEditor_TradeType}
            label={'Trade Type'}
            events={bsProps}
            field={contractNote.tradeType}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ContractNoteEditor_AsxCode}
            label={'ASX Code'}
            events={bsProps}
            field={contractNote.asxCode}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ContractNoteEditor_Market}
            label={'Market'}
            events={bsProps}
            field={contractNote.market}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ContractNoteEditor_SecurityName}
            label={'Security Name'}
            events={bsProps}
            field={contractNote.securityName}
            className={bsProps.className}
          />
        </Grid>
        <Grid item xs={6}>
          <FormStringField
            id={FieldAndColumnName.ContractNoteEditor_SecurityType}
            label={'Security Type'}
            events={bsProps}
            field={contractNote.securityType}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.ContractNoteEditor_TransactionDate}
            label={'Transaction Date'}
            events={bsProps}
            field={contractNote.transactionDate}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.ContractNoteEditor_SettlementDate}
            label={'Settlement Date'}
            events={bsProps}
            field={contractNote.settlementDate}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDoubleField
            id={FieldAndColumnName.ContractNoteEditor_Unit}
            label={'Unit'}
            events={bsProps}
            field={contractNote.unit}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.ContractNoteEditor_UnitPrice}
            label={'Unit Price'}
            events={bsProps}
            field={contractNote.unitPrice}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.ContractNoteEditor_Brokerage}
            label={'Brokerage'}
            events={bsProps}
            field={contractNote.brokerage}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.ContractNoteEditor_Gst}
            label={'GST'}
            events={bsProps}
            field={contractNote.gst}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.ContractNoteEditor_AccruedInterest}
            label={'Accrued Interest(Only for Bonds)'}
            events={bsProps}
            field={contractNote.accruedInterest}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDateField
            id={FieldAndColumnName.ContractNoteEditor_MaturityDate}
            label={'Maturity Date(Only for Bonds)'}
            events={bsProps}
            field={contractNote.maturityDate}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
            allowBeyondToday
          />
        </Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.ContractNoteEditor_Consideration}
            label={'Consideration'}
            events={bsProps}
            field={contractNote.consideration}
            className={bsProps.className}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <FormDollarField
            id={FieldAndColumnName.ContractNoteEditor_TotalAmount}
            label={'Total Amount'}
            events={bsProps}
            field={contractNote.totalAmount}
            className={clsx([styles.TotalAmount, bsProps.className])}
            readonlyMode={bsProps.readonlyMode}
          />
        </Grid>
      </Grid>
    </form>
  )
}
