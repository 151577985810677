import {List} from 'immutable'
import * as _ from 'lodash'
import { Polygon } from '../debug/ocr/OcrElement'

export function compact<T>(list: List<T | undefined>): List<T> {
  return list.filter(value => value !== undefined).map(value => value!)
}

export function flatten<T>(list: List<List<T>>): List<T> {
  return list.flatMap(l => l)
}

export function mathRound(num: number, decimalScale: number = 2): number {
  return Number(`${Math.round(Number(`${num}e${decimalScale}`))}e-${decimalScale}`) || 0
}

export function minMaxDifference(nums: number[]): number {
  return _.flow([_.over([Math.min, Math.max]), minMax => minMax[1] - minMax[0]])(...nums)
}

export function getLineLength(pointA: Polygon, pointB: Polygon): number {
  return Math.sqrt((pointA.x - pointB.x)**2 + (pointA.y - pointB.y)**2)
}

export function getMidPoint(pointA: Polygon, pointB: Polygon): Polygon {
  return { x: (pointA.x + pointB.x) / 2, y: (pointA.y + pointB.y) / 2 }
}

export function applyAngleToPoint(point: Polygon, angle: number, pageWidth: number, pageHeight: number): Polygon {
  const sin = Math.sin(Math.PI / 180 * angle)
  const cos = Math.cos(Math.PI / 180 * angle)
  const a = point.x * pageWidth - pageWidth / 2
  const b = point.y * pageHeight - pageHeight / 2
  return {
    x: (a * cos - b * sin + pageWidth / 2) / pageWidth,
    y: (a * sin + b * cos + pageHeight / 2) / pageHeight
  }
}

export function uuid(): string {
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

export function onPassword(callback: (password: string) => void, reason: string, onDismiss?: () => void) {
  function callbackProxy(password: string | null) {
    // Cancel button handler
    if (password === null) {
      // close the pdf use reset to change the state from consumer side, such as set the pdf Blob to null
      // without reset, the prompt will be closed as well but the pdf will still under rendering condition showing the loading icon
      // Consuming side need to handle the UI change
      onDismiss && onDismiss()
      return
    }
    //   only loop the prompt when the OK button is clicked
    callback(password);
  }
  // reason is a string, but it is a number in fact, library issue
  const reasonNum = parseInt(reason)
  switch (reasonNum) {
    // Case 1 represents NEED_PASSWORD
    case 1: {
      const password = prompt('Enter the password to open this PDF file.');
      callbackProxy(password);
      break;
    }
    // Case 2 represents INCORRECT_PASSWORD
    case 2: {
      const password = prompt('Invalid password. Please try again.');
      callbackProxy(password);
      break;
    }
    default:
  }
}

export enum PdfMode {
  StandardScrollModeWithToolbar = 'standardScrollModeWithToolbar',
  MobileScrollMode = 'mobileScrollMode',
  MobileSinglePageMode = 'mobileSinglePageMode',
}
