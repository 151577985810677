import Grid from '@material-ui/core/Grid'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import React, { FC } from 'react'
import { WaterBill } from '../../domain/businessModel/waterbill/WaterBill'
import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormDoubleField } from '../FormDoubleField'
import { FormStringField } from '../FormStringField'
import { UtilityBill } from 'domain/businessModel/utilityBill/UtilityBill'
import { FormStringFieldArray } from 'components/FormStringFieldArray'
import { List } from 'immutable'
import {createStyles, makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

interface IWaterBillProps extends EditorFieldEvents {
  waterBill?: WaterBill,
  utilityBill?: UtilityBill,
  className?: string
  readonlyMode?: boolean
}

const useStyles = makeStyles(() =>
    createStyles({
      totalAmount: {
        '& label': {
          fontWeight: 600,
          color: 'black',
          fontSize: '1.1rem'
        }
      }
    })
)
export const WaterBillEditor: FC<IWaterBillProps> = (props: IWaterBillProps) =>{
  const style = useStyles()
  const waterBill = props.waterBill
  const utilityBill = props.utilityBill

  if (waterBill) {
    return (
      <form noValidate autoComplete="no">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.WaterBillEditor_PropertyAddress}
                label={'Property Address'}
                events={props}
                field={waterBill.propertyAddress}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
                id={FieldAndColumnName.WaterBillEditor_DueDate}
                label={'Due Date'}
                events={props}
                field={waterBill.dueDate}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
                id={FieldAndColumnName.WaterBillEditor_IssueDate}
                label={'Issue Date'}
                events={props}
                field={waterBill.issueDate}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDoubleField
                id={FieldAndColumnName.WaterBillEditor_TotalAmount}
                label={'Total Amount'}
                events={props}
                field={waterBill.totalAmount}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
        </Grid>
      </form>
    )
  } else if (utilityBill) {
    return (
      <form noValidate autoComplete="no">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormStringField
                id={FieldAndColumnName.UtilityBillEditor_PropertyAddress}
                label={'Property Address'}
                events={props}
                field={utilityBill.propertyAddress}
                className={clsx([style.totalAmount, props.className])}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
                id={FieldAndColumnName.UtilityBillEditor_DueDate}
                label={'Due Date'}
                events={props}
                field={utilityBill.dueDate}
                className={clsx([style.totalAmount, props.className])}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
                id={FieldAndColumnName.UtilityBillEditor_IssueDate}
                label={'Issue Date'}
                events={props}
                field={utilityBill.issueDate}
                className={clsx([style.totalAmount, props.className])}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDoubleField
                id={FieldAndColumnName.UtilityBillEditor_TotalAmount}
                label={'Total Amount'}
                events={props}
                field={utilityBill.totalAmount}
                className={clsx([style.totalAmount, props.className])}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.UtilityBillEditor_AccountNumber}
                label={'Account Number'}
                events={props}
                field={utilityBill.accountNumber}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.UtilityBillEditor_InvoiceNumber}
                label={'Invoice Number'}
                events={props}
                field={utilityBill.invoiceNumber}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.UtilityBillEditor_CustomerABN}
                label={'Customer ABN'}
                events={props}
                field={utilityBill.customerABN}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormStringField
                id={FieldAndColumnName.UtilityBillEditor_CustomerName}
                label={'Customer Name'}
                events={props}
                field={utilityBill.customerName}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={12}>
            <FormStringField
                id={FieldAndColumnName.UtilityBillEditor_CustomerAddress}
                label={'CustomerAddress'}
                events={props}
                field={utilityBill.customerAddress}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.UtilityBillEditor_SupplierName}
                label={'Supplier Name'}
                events={props}
                field={utilityBill.supplierName}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormStringField
                id={FieldAndColumnName.UtilityBillEditor_SupplierABN}
                label={'Supplier ABN'}
                events={props}
                field={utilityBill.supplierABN}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
                id={FieldAndColumnName.UtilityBillEditor_BillingStartDate}
                label={'Billing Start Date'}
                events={props}
                field={utilityBill.billingStartDate}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDateField
                id={FieldAndColumnName.UtilityBillEditor_BillingEndDate}
                label={'Billing End Date'}
                events={props}
                field={utilityBill.billingEndDate}
                className={props.className}
                readonlyMode={props.readonlyMode}
            />
          </Grid>
          <Grid item xs={6}>
              <FormStringFieldArray
                  id={FieldAndColumnName.UtilityBillEditor_ServicePhoneNumber}
                  label={'Service Phone Number'}
                  events={props}
                  fields={utilityBill.servicePhoneNumber ?? List()}
                  className={props.className}
              />
          </Grid>
        </Grid>
      </form>
    )
  } else {
    return <></>
  }
  
}
