import { Button } from '@material-ui/core'
import React, { lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'

import ContactUs from './components/contactUs/ContactUs'
import Home from './components/Home'
import HomeLayout from './components/Home/HomeLayout'
import PretrainedAIModels from './components/Home/PretrainedAIModels'
import PricingTab from './components/Home/PricingTab'
import Redaction from './components/Home/redaction'
import Loading from './components/others/Loading'
import PublicRouter from './components/router/PublicRouter'
import SignUpForm from './components/SignUpForm'
import Layout from './pages/Layout'
import ProtectRouter from './ProtectRouter'

const MyProjects = lazy(() => import('./components/MyProjects'))
const ProjectDashboard = lazy(() => import('./components/ProjectDashboard'))
const TrainingFiles = lazy(() => import('./components/TrainingFiles'))
const TrainingJobs = lazy(() => import('./components/TrainingJobs'))
const ProcessingFiles = lazy(() => import('./components/ProcessingFiles'))
const MyAccount = lazy(() => import('./components/MyAccount'))
const AiChat = lazy(() => import('./pages/AiChat'))

const SuspenseWrapper = (children: JSX.Element) => {
  return <Suspense fallback={<Loading />}>{children}</Suspense>
}

const AppRoutes = () => {
  return (
    <HashRouter>
      <Routes>
        <Route
          path={'business/:businessId'}
          element={
            <ProtectRouter>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Layout />
              </ErrorBoundary>
            </ProtectRouter>
          }
        >
          <Route index element={<Navigate to={'projects'} replace={true} />} />
          <Route
            path="projects"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                {SuspenseWrapper(<MyProjects />)}
              </ErrorBoundary>
            }
          />
          <Route
            path="chat"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                {SuspenseWrapper(<AiChat />)}
              </ErrorBoundary>
            }
          />
          <Route
            path="settings"
            element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                {SuspenseWrapper(<MyAccount />)}
              </ErrorBoundary>
            }
          />
          <Route path="project/:projectId">
            <Route
              index
              element={<Navigate to={'dashboard'} replace={true} />}
            />
            <Route
              path="dashboard"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {SuspenseWrapper(<ProjectDashboard />)}
                </ErrorBoundary>
              }
            />
            <Route
              path="label"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {SuspenseWrapper(<TrainingFiles />)}
                </ErrorBoundary>
              }
            />
            <Route
              path="train"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {SuspenseWrapper(<TrainingJobs />)}
                </ErrorBoundary>
              }
            />
            <Route
              path="use"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {SuspenseWrapper(<ProcessingFiles />)}
                </ErrorBoundary>
              }
            />
            <Route
              path="knowledge"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {SuspenseWrapper(<TrainingFiles />)}
                </ErrorBoundary>
              }
            />
            <Route
              path="chat"
              element={
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {SuspenseWrapper(<AiChat />)}
                </ErrorBoundary>
              }
            />
            <Route
              path="*"
              element={<Navigate to={'dashboard'} replace={true} />}
            />
          </Route>
          <Route
            path="*"
            element={<Navigate to={'projects'} replace={true} />}
          />
        </Route>
        <Route path="/" element={<PublicRouter />}>
          <Route
            index
            element={
              <HomeLayout>
                <Home />
              </HomeLayout>
            }
          />
          <Route
            path="pricing"
            element={
              <HomeLayout>
                <PricingTab />
              </HomeLayout>
            }
          />
          <Route
            path="privacy-redaction"
            element={
              <HomeLayout>
                <Redaction />
              </HomeLayout>
            }
          />
          {/* for external use */}
          <Route
            path="Pre-trained-AI-Models"
            element={<PretrainedAIModels />}
          />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="sign-up" element={<SignUpForm />} />
        </Route>
      </Routes>
    </HashRouter>
  )
}

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </div>
  )
}

export default AppRoutes
