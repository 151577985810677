import React, {FunctionComponent} from "react"
import {DateField} from "../domain/businessModel/DetectedField"
import {List} from "immutable"
import {EditorFieldEvents} from "./EditorFieldEvents"
import { FieldAndColumnName } from "domain/validator/FieldValidatorDef"
import {FormDateField} from "./FormDateField";

export const FormDateFieldArray: FunctionComponent<{ id: FieldAndColumnName, label: string, fields: List<DateField>, events: EditorFieldEvents, className?: string}> =
    ({
         id,
         label,
         fields,
         events,
         className
     }) => {
        if(fields && fields.size !== 0 ) {
            return(
                <>
                    {fields.map((field, index) =>
                        <FormDateField key={index} id={id} label={label} events={events} field={field} className={className}/>
                    )}
                </>
            )
        }
        return <FormDateField id={id} label={label} events={events} field={undefined} className={className}/>
    }
