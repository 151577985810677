import { PayGInstalmentYearlyTable } from '@bgl/textract-business-model-editor'
import type { GridColDef } from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import React, { useMemo } from 'react'

interface IPaygInstalmentYearlyTableEditorProps {
  payGInstalmentYearlyTable: PayGInstalmentYearlyTable
}

export const PayGInstalmentYearlyTableEditor = (
  props: IPaygInstalmentYearlyTableEditorProps
) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  const columns: GridColDef[] = useMemo(
    () => PayGInstalmentYearlyTable.toGridColDef(),
    []
  )
  const rows = props.payGInstalmentYearlyTable.toGridRowsProp()
  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
    />
  )
}

export default PayGInstalmentYearlyTableEditor
