import { TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton
} from '@material-ui/data-grid'
import React from 'react'
import { ChartOfAccounts360 } from '../../domain/businessModel/ChartOfAccounts360/ChartOfAccounts360'
import { ChartOfAccounts360PageTable } from '../../domain/businessModel/ChartOfAccounts360/ChartOfAccounts360PageTable'


interface IChartOfAccounts360EditorProps {
  chartOfAccounts360: ChartOfAccounts360
}

export const ChartOfAccounts360Editor = (props: IChartOfAccounts360EditorProps) => {

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton/>
        <GridToolbarDensitySelector/>
        <GridToolbarExport/>
      </GridToolbarContainer>
    )
  }

    function renderLongTextCell(params: GridCellParams) {
        return (
            <span title={params.value as string} style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>{params.value}</span>
        )
    }

  const columns: GridColDef[] = ChartOfAccounts360PageTable.toGridColDef(renderLongTextCell)
  const rows = props.chartOfAccounts360.chartOfAccountsPages.toGridRowsProp()

  return (
      <form noValidate autoComplete="no">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={'Code'}
              defaultValue={props.chartOfAccounts360.code}
              fullWidth
              multiline
              placeholder = "N/A"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Name'}
              defaultValue={props.chartOfAccounts360.name}
              fullWidth
              multiline
              placeholder = "N/A"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Probability'}
              defaultValue={props.chartOfAccounts360.probability}
              fullWidth
              multiline
              placeholder = "N/A"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              disableColumnMenu
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              components={{
                Toolbar: CustomToolbar
              }}
            />
          </Grid>
        </Grid>
      </form>
  )
}

export default ChartOfAccounts360Editor
