import { GridColDef, GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'
import React from 'react'
import { FieldAndColumnName } from '../../validator/FieldValidatorDef'
import { DetectedField } from '../DetectedField'
import ModelObject from '../ModelObject'
import { PayGInstalmentYearlyTableRow } from './PayGInstalmentYearlyTableRow'

export class PayGInstalmentYearlyTable extends ModelObject {
  constructor(
    readonly payGInstalmentYearlyTableRows: List<PayGInstalmentYearlyTableRow>
  ) {
    super()
  }

  tableRowByIndex(index: number): PayGInstalmentYearlyTableRow | undefined {
    return this.payGInstalmentYearlyTableRows.get(index)
  }

  listFields(): List<DetectedField> {
    return this.payGInstalmentYearlyTableRows.reduce((combined, PayGInstalmentYearlyTableRow) => {
      return combined.concat(PayGInstalmentYearlyTableRow.listFields())
    }, List<DetectedField>())
  }

  static toGridColDef(): GridColDef[] {
    return [
      {
        field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_TfnNumber,
        type: 'string',
        headerName: 'TFN',
        description: 'Tfn Number',
        width: 100,
        editable: true
      },
      {
        field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_ClientName,
        type: 'string',
        headerName: 'CLIENT NAME',
        description: 'Client Name',
        width: 200,
        editable: true
      },
      {
        field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter1,
        type: 'number',
        headerName: 'Q1',
        width: 80,
        editable: true
      },
      {
        field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter2,
        type: 'number',
        headerName: 'Q2',
        width: 80,
        editable: true
      },
      {
        field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter3,
        type: 'number',
        headerName: 'Q3',
        width: 80,
        editable: true
      },
      {
        field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter4,
        type: 'number',
        headerName: 'Q4',
        width: 80,
        editable: true
      },
      {
        field: FieldAndColumnName.PayGInstalmentYearlyTableColumn_TotalInstalment,
        type: 'number',
        headerName: 'TOTAL INSTALMENT',
        width: 100,
        editable: true
      }
    ]
  }

  toGridRowsProp(): GridRowsProp {
    return this.payGInstalmentYearlyTableRows.map((item, index) => {
      return item.toGridRow(index)
    }).toArray()
  }

  size(): number {
    return this.payGInstalmentYearlyTableRows.size
  }
}
