import {
  DetectedField,
  FieldAndColumnName,
  Receipt,
  ReceiptItem,
  ReceiptItemColumn
} from '@bgl/textract-business-model-editor'
import type {
  GridCellParams,
  GridColDef,
  GridEditCellValueParams,
  GridRowsProp
} from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import React from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'

interface IReceiptItemsEditorProp extends EditorFieldEvents {
  receipt?: Receipt
  onCellClick?: (
    receipt: Receipt,
    item: ReceiptItem,
    field: DetectedField
  ) => void
}

export const ReceiptItemsEditor = (itemsProp: IReceiptItemsEditorProp) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function onCellClick(params: GridCellParams) {
    if (itemsProp.onCellClick && itemsProp.receipt) {
      const columnName = params.field
      const rowId = +params.id
      const receipt = itemsProp.receipt
      const item = receipt.byIndex(rowId)
      const field = item?.fieldByColumn(columnName as ReceiptItemColumn)
      if (item && field) {
        itemsProp.onCellClick(receipt, item, field)
      } else {
        console.error(
          `not able to field cell based on column ${columnName} and rowId ${rowId}`
        )
      }
    }
  }

  const columns: GridColDef[] = Receipt.toGridColDef()

  const rows: GridRowsProp = itemsProp.receipt?.toGridRowsProp() ?? []

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      components={{
        Toolbar: CustomToolbar
      }}
      onCellClick={onCellClick}
      onCellValueChange={(params: GridEditCellValueParams) => {
        // handleGridInputValue(params.field, params.id, params.value, record)
        itemsProp.onGridValueChanged?.(
          params.field as FieldAndColumnName,
          params.id,
          params.value
        )
      }}
    />
  )
}
