import {IBusinessModel, IModelType} from 'domain/types/ISmartDocsResult'
import {FieldAndColumnName} from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import {FormGridRowValue} from 'utils/DataTypeMapper'
import {uuid} from 'utils/utils'
import BusinessModel from '../BusinessModel'
import {DateField, DetectedField, DollarField, DoubleField, StringField} from '../DetectedField'
import {ContractNoteMapper} from './ContractNoteMapper'


export class ContractNote extends BusinessModel {
  constructor(
    version: string,
    readonly brokerName?: StringField,
    readonly tradeType?: StringField,
    readonly totalAmount?: DollarField,
    readonly transactionDate?: DateField,
    readonly settlementDate?: DateField,
    readonly unit?: DoubleField,
    readonly unitPrice?: DollarField,
    readonly consideration?: DollarField,
    readonly brokerage?: DollarField,
    readonly gst?: DollarField,
    readonly referenceNumber?: StringField,
    readonly asxCode?: StringField,
    readonly otherFee?: DollarField,
    readonly securityName?:StringField,
    readonly securityType?:StringField,
    readonly market?:StringField,
    readonly accruedInterest?:DollarField,
    readonly maturityDate?:DateField
  ) {
    super(IModelType.ContractNote, version)
  }

  protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
    switch (id) {
      case FieldAndColumnName.ContractNoteEditor_TradeType:
      case FieldAndColumnName.ContractNoteEditor_AsxCode:
      case FieldAndColumnName.ContractNoteEditor_Market:
      case FieldAndColumnName.ContractNoteEditor_ReferenceNumber:
      case FieldAndColumnName.ContractNoteEditor_SecurityName:
      case FieldAndColumnName.ContractNoteEditor_SecurityType:
      case FieldAndColumnName.ContractNoteEditor_BrokerName:
        return new StringField(uuid(), value as string, [], List(), modifiedBy)

      case FieldAndColumnName.ContractNoteEditor_TransactionDate:
      case FieldAndColumnName.ContractNoteEditor_SettlementDate:
      case FieldAndColumnName.ContractNoteEditor_MaturityDate:
        return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)

      case FieldAndColumnName.ContractNoteEditor_Unit:
        return new DoubleField(uuid(), value as number, [], List(), modifiedBy)

      case FieldAndColumnName.ContractNoteEditor_UnitPrice:
      case FieldAndColumnName.ContractNoteEditor_Brokerage:
      case FieldAndColumnName.ContractNoteEditor_Gst:
      case FieldAndColumnName.ContractNoteEditor_Consideration:
      case FieldAndColumnName.ContractNoteEditor_TotalAmount:
      case FieldAndColumnName.ContractNoteEditor_AccruedInterest:
        return new DollarField(uuid(), value as number, [], List(), modifiedBy)

      default:
        return undefined
    }
  }

  copy({
    version = this.version,
    brokerName = this.brokerName,
    tradeType = this.tradeType,
    totalAmount = this.totalAmount,
    transactionDate = this.transactionDate,
    settlementDate = this.settlementDate,
    unit = this.unit,
    unitPrice = this.unitPrice,
    consideration = this.consideration,
    brokerage = this.brokerage,
    gst = this.gst,
    referenceNumber = this.referenceNumber,
    asxCode = this.asxCode,
    otherFee = this.otherFee,
    securityName = this.securityName,
    securityType = this.securityType,
    market = this.market,
    accruedInterest = this.accruedInterest,
    maturityDate = this.maturityDate
       }): ContractNote {
    return new ContractNote(
      version, brokerName, tradeType, totalAmount, transactionDate, settlementDate, unit, unitPrice,
      consideration, brokerage, gst, referenceNumber, asxCode, otherFee,securityName,
      securityType,market,accruedInterest,maturityDate
    )
  }

  toJson(): IBusinessModel {
      return ContractNoteMapper.toJson(this)
  }

  getShareCode(): string {
    return this.asxCode?.parsedValue || ''
  }

  getPeriodStartDate(): moment.Moment | undefined {
    return this.transactionDate?.parsedValue
  }

  getPeriodEndDate(): moment.Moment | undefined {
    return this.transactionDate?.parsedValue
  }

  getTotalAmount(): number {
    return this.totalAmount?.parsedValue || 0
  }
}
