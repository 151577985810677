import { GridCellParams, GridColDef, GridRowData, GridRowsProp } from '@material-ui/data-grid'
import { List } from 'immutable'
import React from 'react'
import { FieldAndColumnName } from '../../validator/FieldValidatorDef'

export class ChartOfAccounts360PageTableRow {
  constructor(
    readonly id: string,
    readonly code?: string,
    readonly name?: string,
    readonly probability?: number
  ) {
  }

  toGridRow(id: number): GridRowData {
    return {
      id,
      [FieldAndColumnName.ChartOfAccounts360PagesEditor_Code]: this.code,
      [FieldAndColumnName.ChartOfAccounts360PagesEditor_Name]: this.name,
      [FieldAndColumnName.ChartOfAccounts360PagesEditor_Probability]: this.probability
    }
  }
}

export class ChartOfAccounts360PageTable {
  constructor(
    readonly chartOfAccounts360PageTableRows: List<ChartOfAccounts360PageTableRow>
  ) {
  }

  static toGridColDef = (renderLongTextCell: (params: GridCellParams) => JSX.Element): GridColDef[] => {
    return [
      {
        field: 'id',
        type: 'string',
        headerName: 'Page',
        width: 80,
        editable: false
      },
      {
        field: FieldAndColumnName.ChartOfAccounts360PagesEditor_Code,
        type: 'string',
        headerName: 'Code',
        width: 80,
        editable: true
      },
      {
        field: FieldAndColumnName.ChartOfAccounts360PagesEditor_Name,
        type: 'string',
        headerName: 'Name',
        width: 200,
        editable: true,
        renderCell: (params: GridCellParams) => renderLongTextCell(params)
      },
      {
        field: FieldAndColumnName.ChartOfAccounts360PagesEditor_Probability,
        type: 'number',
        headerName: 'Probability',
        width: 100,
        editable: true,
        headerAlign: 'left',
        align: 'left'
      }
    ]
  }

  toGridRowsProp(): GridRowsProp {
    return this.chartOfAccounts360PageTableRows.map((row, index) => row.toGridRow(index+1)
    ).toArray()
  }

  size(): number {
    return this.chartOfAccounts360PageTableRows.size
  }

}
