import { GridRowData } from '@material-ui/data-grid'
import { List } from 'immutable'
import { FormGridRowValue } from '../../../utils/DataTypeMapper'
import { uuid } from '../../../utils/utils'
import { FieldAndColumnName } from '../../validator/FieldValidatorDef'
import { DetectedField, DollarField, StringField } from '../DetectedField'
import IItemObject from '../IItemObject'
import ModelObject from '../ModelObject'

export type PayGInstalmentYearlyTableColumn =
  FieldAndColumnName.PayGInstalmentYearlyTableColumn_TfnNumber
  | FieldAndColumnName.PayGInstalmentYearlyTableColumn_ClientName
  | FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter1
  | FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter2
  | FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter3
  | FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter4
  | FieldAndColumnName.PayGInstalmentYearlyTableColumn_TotalInstalment

export class PayGInstalmentYearlyTableRow extends ModelObject implements IItemObject {
  constructor(
    readonly tfnNumber?: StringField,
    readonly clientName?: StringField,
    readonly quarter1?: DollarField,
    readonly quarter2?: DollarField,
    readonly quarter3?: DollarField,
    readonly quarter4?: DollarField,
    readonly totalInstalment?: DollarField
  ) {
    super()
  }

  addColumn(
    gridColumnName: FieldAndColumnName,
    newValue: FormGridRowValue,
    modifiedBy: string
  ): PayGInstalmentYearlyTableRow {
    switch(gridColumnName) {
      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_TfnNumber:
        return this.copy({ tfnNumber: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_ClientName:
        return this.copy({ clientName: new StringField(uuid(), newValue as string, [], List(), modifiedBy)})

      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter1:
        return this.copy({ quarter1: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter2:
        return this.copy({ quarter2: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter3:
        return this.copy({ quarter3: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter4:
        return this.copy({ quarter4: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_TotalInstalment:
        return this.copy({ totalInstalment: new DollarField(uuid(), newValue as number, [], List(), modifiedBy)})

      default:
        return this
    }
  }

  deleteColumn(gridColumn: FieldAndColumnName): PayGInstalmentYearlyTableRow {
    return this.copy({ [gridColumn]: null })
  }

  fieldByColumn(column: FieldAndColumnName): DetectedField | undefined {
    switch (column) {
      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_TfnNumber:
        return this.tfnNumber
      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_ClientName:
        return this.clientName
      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter1:
        return this.quarter1
      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter2:
        return this.quarter2
      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter3:
        return this.quarter3
      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter4:
        return this.quarter4
      case FieldAndColumnName.PayGInstalmentYearlyTableColumn_TotalInstalment:
        return this.totalInstalment
      default:
        return undefined
    }
  }

  copy({
         tfnNumber = this.tfnNumber,
          clientName = this.clientName,
          quarter1 = this.quarter1,
          quarter2 = this.quarter2,
          quarter3 = this.quarter3,
          quarter4 = this.quarter4,
          totalInstalment = this.totalInstalment
       }): PayGInstalmentYearlyTableRow {
    return new PayGInstalmentYearlyTableRow(
      tfnNumber,
      clientName,
      quarter1,
      quarter2,
      quarter3,
      quarter4,
      totalInstalment
    )
  }

  toGridRow(id: number): GridRowData {
    return {
      id,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_TfnNumber]: this.tfnNumber?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_ClientName]: this.clientName?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter1]: this.quarter1?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter2]: this.quarter2?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter3]: this.quarter3?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_Quarter4]: this.quarter4?.parsedValue,
      [FieldAndColumnName.PayGInstalmentYearlyTableColumn_TotalInstalment]: this.totalInstalment?.parsedValue
    }
  }

  listFields(): List<DetectedField> {
    return DetectedField.detectedFieldFromObject(this)
  }
}
