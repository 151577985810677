import Grid from '@material-ui/core/Grid'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import React from 'react'
import {DetectedField} from '../../domain/businessModel/DetectedField'
import {Invoice} from '../../domain/businessModel/invoice/Invoice'
import {InvoiceItem} from '../../domain/businessModel/invoice/InvoiceItem'
import {EditorFieldEvents} from '../EditorFieldEvents'
import {FormDateField} from '../FormDateField'
import {FormDoubleField} from '../FormDoubleField'
import {FormStringField} from '../FormStringField'
import {InvoiceItemsEditor} from './InvoiceItemEditor'

interface IInvoiceEditorProp extends EditorFieldEvents{
    invoiceProps: Invoice
    className?: string
}

export const InvoiceEditor = (props: IInvoiceEditorProp) => {
    function onCellClick(invoice: Invoice, item: InvoiceItem, field: DetectedField) {
        props.onFieldFocus?.(undefined, field)
    }

    const invoice = props.invoiceProps

    return (
        <form noValidate autoComplete="on">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_CustomerName}
                        label={'Customer Name'}
                        events={props}
                        field={invoice.customerName}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_CustomerId}
                        label={'Customer Id'}
                        events={props}
                        field={invoice.customerId}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_PurchaseOrder}
                        label={'Purchase Order'}
                        events={props}
                        field={invoice.purchaseOrder}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_InvoiceId}
                        label={'Invoice Id'}
                        events={props}
                        field={invoice.invoiceId}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDateField
                        id={FieldAndColumnName.InvoiceEditor_InvoiceDate}
                        label={'Invoice Date'}
                        events={props}
                        field={invoice.invoiceDate}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDateField
                        id={FieldAndColumnName.InvoiceEditor_DueDate}
                        label={'Due Date'}
                        events={props}
                        field={invoice.dueDate}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_VendorName}
                        label={'Vendor Name'}
                        events={props}
                        field={invoice.vendorName}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_VendorAddress}
                        label={'Vendor Address'}
                        events={props}
                        field={invoice.vendorAddress}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_VendorAddressRecipient}
                        label={'Vendor Address Recipient'}
                        events={props}
                        field={invoice.vendorAddressRecipient}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_CustomerAddress}
                        label={'Customer Address'}
                        events={props}
                        field={invoice.customerAddress}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_CustomerAddressRecipient}
                        label={'Customer Address Recipient'}
                        events={props}
                        field={invoice.customerAddressRecipient}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_BillingAddress}
                        label={'Billing Address'}
                        events={props}
                        field={invoice.billingAddress}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_BillingAddressRecipient}
                        label={'Billing Address Recipient'}
                        events={props}
                        field={invoice.billingAddressRecipient}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_ShippingAddress}
                        label={'Shipping Address'}
                        events={props}
                        field={invoice.shippingAddress}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.InvoiceEditor_ShippingAddressRecipient}
                        label={'Shipping Address Recipient'}
                        events={props}
                        field={invoice.shippingAddressRecipient}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.InvoiceEditor_SubTotal}
                        label={'Sub Total'}
                        events={props}
                        field={invoice.subTotal}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.InvoiceEditor_TotalTax}
                        label={'Total Tax'}
                        events={props}
                        field={invoice.totalTax}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.InvoiceEditor_InvoiceTotal}
                        label={'Invoice Total'}
                        events={props}
                        field={invoice.invoiceTotal}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.InvoiceEditor_AmountDue}
                        label={'Amount Due'}
                        events={props}
                        field={invoice.amountDue}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InvoiceItemsEditor
                        invoice={invoice}
                        onCellClick={onCellClick}
                        onGridValueChanged={props.onGridValueChanged}
                    ></InvoiceItemsEditor>
                </Grid>
            </Grid>
        </form>
    )
}
