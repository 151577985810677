import SmartDocPDFViewer, {IOverlay, pdfjs} from '@bgl/textract-doc-viewer'
import autobind from 'autobind-decorator'
import React from 'react'


interface DocViewerProps {
  pdfBlob: Blob,
  fileName: string,
  onDocumentReady?: (pdf: pdfjs.PDFDocumentProxy) => void
  onPasswordCancel?: () => void
  disableDownload?: boolean
}

@autobind
export default class DocViewer extends React.Component<DocViewerProps> {
  constructor(props: DocViewerProps) {
    super(props)
  }

  private pdfViewer: SmartDocPDFViewer | null = null

  render() {
    return (
      <SmartDocPDFViewer
        ref={ref => {
          this.pdfViewer = ref
        }}
        pdfBlob={this.props.pdfBlob}
        fileName={this.props.fileName}
        overlays={[]}
        pageTransforms={[]}
        onDocumentReady={this.props.onDocumentReady}
        onPasswordCancel={this.props.onPasswordCancel}
        disableDownload={this.props.disableDownload}
      />
    )
  }
}
