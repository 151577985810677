import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton
} from '@material-ui/data-grid'
import React, { useMemo } from 'react'
import {BankAuthorityAccounts} from '../../domain/businessModel/bankAuthority/BankAuthorityAccounts'
import { EditorFieldEvents } from '../EditorFieldEvents'

interface IBankAuthorityTableEditorProps extends EditorFieldEvents {
    accounts?: BankAuthorityAccounts
}

export const BankAuthorityAccountsTableEditor = (props: IBankAuthorityTableEditorProps) => {
    const columns: GridColDef[] = useMemo(() => BankAuthorityAccounts.toGridColDef(), [])
    const rows = props.accounts?.toGridRowsProp() ?? []
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <GridToolbarExport/>
            </GridToolbarContainer>
        )
    }

    return (
        <DataGrid
            autoHeight
            disableColumnMenu
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            components={{
                Toolbar: CustomToolbar
            }}
        />
    )
}
