import Grid from '@material-ui/core/Grid'
import { FormStringFieldArray } from 'components/FormStringFieldArray'
import { BusinessCardValidatorFactory } from 'domain/validator/BusinessCardFieldValidator'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { FormFieldValidator } from 'domain/validator/FormFieldValidator'
import React, { FunctionComponent } from 'react'
import { FormControlContext } from 'utils/context/FormControlContext'
import useFormControl from 'utils/hooks/useFormControl'
import { BusinessCard } from '../../domain/businessModel/businesscard/BusinessCard'
import {EditorFieldEvents} from '../EditorFieldEvents'
import { FormStringField } from '../FormStringField'

interface IBusinessCardProps extends EditorFieldEvents {
    businessCard: BusinessCard,
    className?: string
}

const fieldNames = [
  FieldAndColumnName.BusinessCardEditor_MobilePhones,
  FieldAndColumnName.BusinessCardEditor_Websites
]

const fieldValidators = FormFieldValidator.generateValidators(BusinessCardValidatorFactory, fieldNames)

export const BusinessCardEditor: FunctionComponent<IBusinessCardProps> = (bcProps: IBusinessCardProps) => {

    const businessCard = bcProps.businessCard

    const { handleInputValue, handleGridInputValue, errors, gridErrors } = useFormControl(
      bcProps.fieldValidators || fieldValidators,
      businessCard,
      FormFieldValidator.emptyValidatorArray,
      FormFieldValidator.emptyGridCellInitialRows,
      bcProps.notifyError
    )

  return (
    <FormControlContext.Provider value={{ handleInputValue, handleGridInputValue, errors, gridErrors, record: businessCard }}>
      <form noValidate autoComplete="on">
        <Grid container spacing={2}>
            {businessCard.contactNames?.map((contactName) => {
                return(
                  <React.Fragment key={contactName.lastName?.id || contactName.firstName?.id } >
                      <Grid item xs={6} >
                      <FormStringField
                      id={FieldAndColumnName.BusinessCardEditor_ContactFirstName}
                      label={'First Name'}
                      events={bcProps}
                      field={contactName.firstName}
                      className={bcProps.className}
                      />
                      </Grid>
                      <Grid item xs={6}>
                      <FormStringField
                          id={FieldAndColumnName.BusinessCardEditor_ContactLastName}
                          label={'Last Name'}
                          events={bcProps}
                          field={contactName.lastName}
                          className={bcProps.className}
                      />
                      </Grid>
                  </React.Fragment>
                )
            })}


          <Grid item xs={8}>
              <FormStringFieldArray
                  id={FieldAndColumnName.BusinessCardEditor_JobTitles}
                  label={'Job Title'}
                  events={bcProps}
                  fields={businessCard.jobTitles}
                  className={bcProps.className}
              />
          </Grid>

          <Grid item xs={8}>
              <FormStringFieldArray
                  id={FieldAndColumnName.BusinessCardEditor_Departments}
                  label={'Departments'}
                  events={bcProps}
                  fields={businessCard.departments}
                  className={bcProps.className}
              />
          </Grid>

          <Grid item xs={8}>
              <FormStringFieldArray
                  id={FieldAndColumnName.BusinessCardEditor_Websites}
                  label={'Websites'}
                  events={bcProps}
                  fields={businessCard.websites}
                  className={bcProps.className}
              />
          </Grid>
          <Grid item xs={8}>
              <FormStringFieldArray
                  id={FieldAndColumnName.BusinessCardEditor_Emails}
                  label={'Emails'}
                  events={bcProps}
                  fields={businessCard.emails}
                  className={bcProps.className}
              />
          </Grid>
          <Grid item xs={8}>
            <FormStringFieldArray
              id={FieldAndColumnName.BusinessCardEditor_MobilePhones}
              label={'Mobiles'}
              events={bcProps}
              fields={businessCard.mobilePhones}
              className={bcProps.className}
            />
          </Grid>
          <Grid item xs={8}>
            <FormStringFieldArray
              id={FieldAndColumnName.BusinessCardEditor_OtherPhones}
              label={'Phones'}
              events={bcProps}
              fields={businessCard.otherPhones}
              className={bcProps.className}
            />
          </Grid>
          <Grid item xs={8}>
            <FormStringFieldArray
              id={FieldAndColumnName.BusinessCardEditor_WorkPhones}
              label={'Work Phones'}
              events={bcProps}
              fields={businessCard.workPhones}
              className={bcProps.className}
            />
          </Grid>
          <Grid item xs={8}>
            <FormStringFieldArray
              id={FieldAndColumnName.BusinessCardEditor_Faxes}
              label={'Faxes'}
              events={bcProps}
              fields={businessCard.faxes}
              className={bcProps.className}
            />
          </Grid>
          <Grid item xs={8}>
            <FormStringFieldArray
              id={FieldAndColumnName.BusinessCardEditor_Address}
              label={'Addresses'}
              events={bcProps}
              fields={businessCard.addresses}
              className={bcProps.className}
            />
          </Grid>
          <Grid item xs={8}>
            <FormStringFieldArray
              id={FieldAndColumnName.BusinessCardEditor_CompanyName}
              label={'Company Names'}
              events={bcProps}
              fields={businessCard.companyNames}
              className={bcProps.className}
            />
          </Grid>
        </Grid>
      </form>
    </FormControlContext.Provider>
  )
}
