import { AtoActivityStatementTransactions } from '@bgl/textract-business-model-editor'
import type { GridCellParams, GridColDef } from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import moment from 'moment'
import React, { useMemo } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'

interface IAtoTransactionTableEditorProps extends EditorFieldEvents {
  transactions?: AtoActivityStatementTransactions
}

export const AtoTransactionTableEditor = (
  props: IAtoTransactionTableEditorProps
) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function renderCell(params: GridCellParams): React.ReactNode {
    const dateString = params.value?.toString()
    const newValue = moment(dateString).format('DD/MM/YYYY')
    return <p>{newValue}</p>
  }

  const columns: GridColDef[] = useMemo(
    () => AtoActivityStatementTransactions.toGridColDef(renderCell),
    []
  )
  const rows = props.transactions?.toGridRowsProp() ?? []
  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
    />
  )
}

export default AtoTransactionTableEditor
