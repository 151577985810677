import { IBusinessModel, IModelType } from 'domain/types/ISmartDocsResult'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import {List} from 'immutable'
import moment from 'moment'
import { FormGridRowValue } from 'utils/DataTypeMapper'
import { uuid } from 'utils/utils'
import BusinessModel from '../BusinessModel'
import {DateField, DetectedField, DoubleField, StringField} from '../DetectedField'
import { DividendStatementMapper } from './DividendStatementMapper'

export class DividendStatement extends BusinessModel {

    constructor(
        version: string,
        readonly shareCode?: StringField,
        readonly dividendFranked?: DoubleField,
        readonly dividendUnfranked?: DoubleField,
        readonly frankingCredits?: DoubleField,
        readonly assessableForeignIncome?: DoubleField,
        readonly foreignIncomeTaxOffset?: DoubleField,
        readonly frankingCreditsFromNZ?: DoubleField,
        readonly withholdingTax?: DoubleField,
        readonly convertedNetDividend?: DoubleField,
        readonly IndividualTrustLIC?: DoubleField,
        readonly SMSFLIC?: DoubleField,
        readonly paymentDate?: DateField,
        readonly recordDate?: DateField,
        readonly numberOfShares?: DoubleField,
        readonly dividendPerShare?: DoubleField,
        readonly grossDividend?: DoubleField,
        readonly netDividend?: DoubleField,
        readonly drpNumberOfShares?: DoubleField,
        readonly preDRPBalanceBringForward?: DoubleField,
        readonly drpBalanceAvailable?: DoubleField,
        readonly drpSharePrice?: DoubleField,
        readonly postDRPShareNumber?: StringField,
        readonly postDRPCreditBalance?: DoubleField,
        readonly companyTaxRate?: DoubleField,
        readonly frankingPercentage?: DoubleField,
        readonly drpShareCost?: DoubleField,
        readonly exchangeRate?: DoubleField,
        readonly foreignCurrency?: StringField,
        readonly supplementaryDividendNZ?: DoubleField,
        readonly feeDeduction?: DoubleField,
        readonly capitalReturn?:DoubleField,
        readonly capitalComponentPerShare?:DoubleField
    ) {
        super(IModelType.DividendStatement, version)
    }

    copy({
        version = this.version,
        shareCode = this.shareCode,
        dividendFranked = this.dividendFranked,
        dividendUnfranked = this.dividendUnfranked,
        frankingCredits = this.frankingCredits,
        assessableForeignIncome = this.assessableForeignIncome,
        foreignIncomeTaxOffset = this.foreignIncomeTaxOffset,
        frankingCreditsFromNZ = this.frankingCreditsFromNZ,
        withholdingTax = this.withholdingTax,
        convertedNetDividend = this.convertedNetDividend,
        IndividualTrustLIC = this.IndividualTrustLIC,
        SMSFLIC = this.SMSFLIC,
        paymentDate = this.paymentDate,
        recordDate = this.recordDate,
        numberOfShares = this.numberOfShares,
        dividendPerShare = this.dividendPerShare,
        grossDividend = this.grossDividend,
        netDividend = this.netDividend,
        drpNumberOfShares = this.drpNumberOfShares,
        preDRPBalanceBringForward = this.preDRPBalanceBringForward,
        drpBalanceAvailable = this.drpBalanceAvailable,
        drpSharePrice = this.drpSharePrice,
        postDRPShareNumber = this.postDRPShareNumber,
        postDRPCreditBalance = this.postDRPCreditBalance,
        companyTaxRate = this.companyTaxRate,
        frankingPercentage = this.frankingPercentage,
        drpShareCost = this.drpShareCost,
        exchangeRate = this.exchangeRate,
        foreignCurrency = this.foreignCurrency,
        supplementaryDividendNZ = this.supplementaryDividendNZ,
        feeDeduction = this.feeDeduction,
        capitalReturn = this.capitalReturn,
        capitalComponentPerShare = this.capitalComponentPerShare
      }): DividendStatement {
        return new DividendStatement(
            version,
            shareCode,
            dividendFranked,
            dividendUnfranked,
            frankingCredits,
            assessableForeignIncome,
            foreignIncomeTaxOffset,
            frankingCreditsFromNZ,
            withholdingTax,
            convertedNetDividend,
            IndividualTrustLIC,
            SMSFLIC,
            paymentDate,
            recordDate,
            numberOfShares,
            dividendPerShare,
            grossDividend,
            netDividend,
            drpNumberOfShares,
            preDRPBalanceBringForward,
            drpBalanceAvailable,
            drpSharePrice,
            postDRPShareNumber,
            postDRPCreditBalance,
            companyTaxRate,
            frankingPercentage,
            drpShareCost,
            exchangeRate,
            foreignCurrency,
            supplementaryDividendNZ,
            feeDeduction,
            capitalReturn,
            capitalComponentPerShare
        )
      }

      protected createMember(id: FieldAndColumnName, value: FormGridRowValue, modifiedBy: string): DetectedField | undefined {
        switch (id) {
            case FieldAndColumnName.DividendStatementEditor_DividendFranked:
            case FieldAndColumnName.DividendStatementEditor_DividendUnfranked:
            case FieldAndColumnName.DividendStatementEditor_FrankingCredits:
            case FieldAndColumnName.DividendStatementEditor_FrankingCreditsFromNZ:
            case FieldAndColumnName.DividendStatementEditor_AssessableForeignIncome:
            case FieldAndColumnName.DividendStatementEditor_ForeignIncomeTaxOffset:
            case FieldAndColumnName.DividendStatementEditor_WithholdingTax:
            case FieldAndColumnName.DividendStatementEditor_ConvertedNetDividend:
            case FieldAndColumnName.DividendStatementEditor_FeeDeduction:
            case FieldAndColumnName.DividendStatementEditor_IndividualTrustLIC:
            case FieldAndColumnName.DividendStatementEditor_SMSFLIC:
            case FieldAndColumnName.DividendStatementEditor_NetPayment:
            case FieldAndColumnName.DividendStatementEditor_GrossPayment:
                return new DoubleField(uuid(), value as number, [], List(), modifiedBy)

            case FieldAndColumnName.DividendStatementEditor_PaymentDate:
              return new DateField(uuid(), value as moment.Moment, [], List(), modifiedBy)

            case FieldAndColumnName.DividendStatementEditor_ShareCode:
              return new StringField(uuid(), value as string, [], List(), modifiedBy)

          default:
            return undefined
        }
      }

      toJson(): IBusinessModel {
        return DividendStatementMapper.toJson(this)
      }

      getShareCode(): string {
        return this.shareCode?.parsedValue || ''
      }

      getPeriodStartDate(): moment.Moment | undefined {
        return this.paymentDate?.parsedValue ? moment(this.paymentDate.parsedValue).subtract(5, 'day') : undefined
      }

      getPeriodEndDate(): moment.Moment | undefined {
        return this.paymentDate?.parsedValue ? moment(this.paymentDate.parsedValue).add(5, 'day') : undefined
      }

      getTotalAmount(): number {
        const grossPayment =
          (this.dividendFranked?.parsedValue || 0)
          + (this.dividendUnfranked?.parsedValue || 0)
          + (this.assessableForeignIncome?.parsedValue || 0)

        const netPayment =
          grossPayment
          - (this.withholdingTax?.parsedValue || 0)

        return netPayment
      }
}
