import { GridColDef, GridRowsProp } from '@material-ui/data-grid'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import { List } from 'immutable'
import { DetectedField } from '../DetectedField'
import ModelObject from '../ModelObject'
import {BankAuthorityAccount, BankAuthorityAccountColumn} from './BankAuthorityAccount'

export class BankAuthorityAccounts extends ModelObject {
    constructor(
        readonly bankAuthorityAccounts: List<BankAuthorityAccount>
    ) {
        super()
    }

    accountByIndex(index: number): BankAuthorityAccount | undefined {
        return this.bankAuthorityAccounts.get(index)
    }

    listFields(): List<DetectedField> {
        return this.bankAuthorityAccounts.reduce((combined, bankAuthorityAccount) => {
            return combined.concat(bankAuthorityAccount.listFields())
        }, List<DetectedField>())
    }
      fieldByColRow(columnName: BankAuthorityAccountColumn, rowIndex: number): DetectedField | undefined {
        const account = this.accountByIndex(rowIndex)
        if (account) {
            return account.fieldByColumn(columnName)
        } else {
            return undefined
        }
    }

    static toGridColDef(): GridColDef[] {
        return [
            {
                field: FieldAndColumnName.BankAuthorityAccounts_AccountName,
                headerName: 'ACCOUNT NAME',
                description: 'Account Name',
                width: 350,
                editable: true
            },
            {
                field: FieldAndColumnName.BankAuthorityAccounts_AccountNumber,
                headerName: 'ACCOUNT NUMBER',
                description: 'Account number',
                width: 200,
                editable: true
            },
            {
                field: FieldAndColumnName.BankAuthorityAccounts_Bsb,
                headerName: 'BSB',
                description: 'bsb',
                width: 200,
                editable: true
            }
        ]
    }

    toGridRowsProp(): GridRowsProp {
        return this.bankAuthorityAccounts.map((item, index) => {
            return item.toGridRow(index)
        }).toArray()
    }

    blockIds(): List<string> {
        return this.bankAuthorityAccounts.flatMap(t => t.blockIds())
    }

    size(): number {
        return this.bankAuthorityAccounts.size
    }

    reduce<T>(f: (reduction: T, value: BankAuthorityAccount, key: number) => T, init: T): T {
        return this.bankAuthorityAccounts.reduce(f, init)
    }

    mapToArray<Z>(mapper: (value: BankAuthorityAccount, key: number) => Z): Z[] {
        return this.bankAuthorityAccounts.map(mapper).valueSeq().toArray()
    }

    reverse(): BankAuthorityAccounts {
        return new BankAuthorityAccounts(this.bankAuthorityAccounts.reverse())
    }
}
