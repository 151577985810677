import type { TransactionColumn } from '@bgl/textract-business-model-editor'
import {
  DetectedField,
  FieldAndColumnName,
  FormControlContext,
  Transaction,
  Transactions
} from '@bgl/textract-business-model-editor'
import { Tooltip } from '@material-ui/core'
import type {
  GridCellParams,
  GridColDef,
  GridEditCellValueParams
} from '@material-ui/data-grid'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@material-ui/data-grid'
import { has } from 'lodash'
import moment from 'moment'
import React, { FunctionComponent, useContext, useMemo } from 'react'

import { EditorFieldEvents } from '../EditorFieldEvents'

interface ITransactionTableEditorProp extends EditorFieldEvents {
  transactions?: Transactions
  onCellClicked?: (
    transactions: Transactions,
    transaction: Transaction,
    field: DetectedField
  ) => void
  tableReadOnly?: boolean
}

export const TransactionTableEditor: FunctionComponent<
  ITransactionTableEditorProp
> = (tableProps: ITransactionTableEditorProp) => {
  const {
    // handleGridInputValue,
    gridErrors
    // record
  } = useContext(FormControlContext)

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function onCellClick(params: GridCellParams) {
    if (tableProps.onCellClicked && tableProps.transactions) {
      const columnName = params.field
      const rowId = +params.id
      const transactions = tableProps.transactions
      const transaction = transactions.transactionByIndex(rowId)
      const field = transactions.fieldByColRow(
        columnName as TransactionColumn,
        rowId
      )
      if (transaction && field) {
        tableProps.onCellClicked(transactions, transaction, field)
      } else {
        console.error(
          `not able to field cell based on column ${columnName} and rowId ${rowId}`
        )
      }
    }
  }

  function renderCell(
    params: GridCellParams,
    errors: { [key: string]: string },
    width: number
  ): React.ReactNode {
    const key = `${params.field}${params.id}`
    if (has(errors, key)) {
      return (
        <Tooltip title={errors[key]} placement="top">
          <div
            style={{
              width: `${width}px`,
              height: '100%',
              borderColor: 'red',
              borderStyle: 'solid',
              borderWidth: '1px',
              marginRight: '-10px',
              marginLeft: '-10px'
            }}
          >
            <div style={{ marginRight: '10px' }}>{params.formattedValue}</div>
          </div>
        </Tooltip>
      )
    }
    return <p>{params.formattedValue}</p>
  }

  const columns: GridColDef[] = useMemo(
    () =>
      Transactions.toGridColDef(
        gridErrors,
        tableProps.tableReadOnly,
        renderCell
      ),
    [gridErrors]
  )

  const rows = tableProps.transactions?.toGridRowsProp() ?? []

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
      onCellClick={onCellClick}
      onCellEditCommit={(params: GridEditCellValueParams) => {
        console.log('onCellValueChange', params)
        let value = params.value
        if (params.field === FieldAndColumnName.TransactionTable_Date) {
          value = moment(params.value as Date)
        }
        tableProps.onGridValueChanged?.(
          params.field as FieldAndColumnName,
          params.id,
          value
        )
      }}
      // onCellValueChange={
      //   (params: GridEditCellValueParams) => {
      //     // NOTE: Do not call handleGridInputValue() to immediately validate new value as onValueChanged() trigger
      //     // a new instance of model been injected to model editor, and useFormControl() validates all fields.
      //     // I intend to leave handleGridInputValue() for later use, redux-form might be used if performance is concern.
      //     // handleGridInputValue(params.field, params.id, params.value, record)
      //     console.log(`onCellValueChange: ${params.field} ${params.id} ${params.value}`)
      //     let value = params.value
      //     if (params.field === FieldAndColumnName.TransactionTable_Date) {
      //       value = moment(params.value as Date)
      //     }
      //     tableProps.onGridValueChanged?.(params.field as FieldAndColumnName, params.id, value)
      //   }
      // }
    />
  )
}
