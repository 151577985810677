import React from "react";
import {
  DataGrid, GridCellParams,
  GridColDef,
  GridEditCellValueParams,
  GridRowsProp,
  GridToolbarContainer, GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@material-ui/data-grid";
import {Receipt} from '../../domain/businessModel/receipt/Receipt'
import {DetectedField} from "../../domain/businessModel/DetectedField"
import {ReceiptItem, ReceiptItemColumn} from "../../domain/businessModel/receipt/ReceiptItem"
import { EditorFieldEvents } from "components/EditorFieldEvents";
import { FieldAndColumnName } from "domain/validator/FieldValidatorDef";

interface IReceiptItemsEditorProp extends EditorFieldEvents {
  receipt?: Receipt
  onCellClick?: (receipt: Receipt, item: ReceiptItem, field: DetectedField) => void
}

export const ReceiptItemsEditor = (itemsProp: IReceiptItemsEditorProp) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton/>
        <GridToolbarDensitySelector/>
        <GridToolbarExport/>
      </GridToolbarContainer>
    )
  }

  function onCellClick(params: GridCellParams) {
    if (itemsProp.onCellClick && itemsProp.receipt) {
      const columnName = params.field
      const rowId = +params.id
      const receipt = itemsProp.receipt
      const item = receipt.byIndex(rowId)
      const field = item?.fieldByColumn(columnName as ReceiptItemColumn)
      if (item && field) {
        itemsProp.onCellClick(receipt, item, field)
      } else {
        console.error(`not able to field cell based on column ${columnName} and rowId ${rowId}`)
      }
    }
  }

  const columns: GridColDef[] = Receipt.toGridColDef()

  const rows: GridRowsProp = itemsProp.receipt?.toGridRowsProp() ?? []

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      rows={rows}
      columns={columns}
      components={{
        Toolbar: CustomToolbar,
      }}
      onCellClick={onCellClick}
      onCellValueChange={
        (params: GridEditCellValueParams) => {
          // handleGridInputValue(params.field, params.id, params.value, record)
          itemsProp.onGridValueChanged?.(params.field as FieldAndColumnName, params.id, params.value)
        }
      }
    />
  )
}
