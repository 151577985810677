import {IBusinessModel, IModelType} from '../../types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DateField, DoubleField, StringField} from '../DetectedField'
import {CouncilRateMapper} from "./CouncilRateMapper";

export class CouncilRate extends BusinessModel{
    constructor(
        modelType: IModelType,
        version: string,
        public readonly propertyAddress?: StringField,
        public readonly issueDate?: DateField,
        public readonly currentAmountDueDate?: DateField,
        public readonly currentAmountDue?:DoubleField,
        public readonly firstInstalmentDueDate?: DateField,
        public readonly firstInstalmentAmount?: DoubleField,
        public readonly secondInstalmentDueDate?: DateField,
        public readonly secondInstalmentAmount?: DoubleField,
        public readonly thirdInstalmentDueDate?: DateField,
        public readonly thirdInstalmentAmount?: DoubleField,
        public readonly fourthInstalmentDueDate?: DateField,
        public readonly fourthInstalmentAmount?: DoubleField,
        public readonly discountTotalAmountDue?: DoubleField,
        public readonly totalAmount?: DoubleField
    ) {
        super(modelType, version)
    }

    copy({
        modelType = this.modelType,
        version = this.version,
        propertyAddress = this.propertyAddress,
        issueDate = this.issueDate,
        currentAmountDueDate = this.currentAmountDueDate,
        currentAmountDue = this.currentAmountDue,
        firstInstalmentDueDate = this.firstInstalmentDueDate,
        firstInstalmentAmount = this.firstInstalmentAmount,
        secondInstalmentDueDate = this.secondInstalmentDueDate,
        secondInstalmentAmount = this.secondInstalmentAmount,
        thirdInstalmentDueDate = this.thirdInstalmentDueDate,
        thirdInstalmentAmount = this.thirdInstalmentAmount,
        fourthInstalmentDueDate = this.fourthInstalmentDueDate,
        fourthInstalmentAmount = this.fourthInstalmentAmount,
        discountTotalAmountDue = this.discountTotalAmountDue,
        totalAmount = this.totalAmount
         }): CouncilRate{
    return new CouncilRate(
        modelType,
        version,
        propertyAddress,
        issueDate,
        currentAmountDueDate,
        currentAmountDue,
        firstInstalmentDueDate,
        firstInstalmentAmount,
        secondInstalmentDueDate,
        secondInstalmentAmount,
        thirdInstalmentDueDate,
        thirdInstalmentAmount,
        fourthInstalmentDueDate,
        fourthInstalmentAmount,
        discountTotalAmountDue,
        totalAmount
    )
}

toJson(): IBusinessModel {
    return CouncilRateMapper.toJson(this)
}
}