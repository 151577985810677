import React, {FunctionComponent} from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbarContainer, GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridRowData, GridCellParams
} from "@material-ui/data-grid"
import {makeStyles} from '@material-ui/core/styles'
import {Map} from "immutable"
import {Row, Table} from "../../domain/ocr/Table"
import {Cell} from "../../domain/ocr/Cell"

interface ITableEditorProp {
  table: Table,
  onCellClicked?: (table: Table, cell: Cell) => void
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDataGrid-row.Mui-odd': {
      backgroundColor: '#efefef'
    },
    '& .MuiDataGrid-cell': {
      border: '1px solid #dddddd'
    }
  }
}))

export const TableEditor: FunctionComponent<ITableEditorProp> = (tableProps: ITableEditorProp) => {
  const TextWidthMultiplier = 10

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton/>
        <GridToolbarDensitySelector/>
        <GridToolbarExport/>
      </GridToolbarContainer>
    )
  }

  function rowToGridRowData(row: Row): GridRowData {
    const id = Map({
      id: row.rowIndex
    })
    const cells = Map<string, string>(row.cells.map(cell => [keyName(cell.columnIndex), cell.text]))
    return id.concat(cells).toObject()
  }

  function keyName(columnIndex: number): string {
    return '' + columnIndex
  }

  function onCellClick(params: GridCellParams) {
    if (tableProps.onCellClicked) {
      const columnIndex = +params.field
      const rowId = +params.id
      const table = tableProps.table
      const cell = table.cellByColRow(columnIndex, rowId)
      if (cell) {
        tableProps.onCellClicked(table, cell)
      } else {
        console.error(`not able to find cell based on column ${columnIndex} and rowId ${rowId}`)
      }
    }
  }

  const table = tableProps.table
  const columns: GridColDef[] = table.columns().map(column => {
    return {
      field: keyName(column.columnIndex),
      headerName: `Col ${column.columnIndex}`,
      width: column.maxColumnTextLength * TextWidthMultiplier
    }
  }).toArray()

  const rows: GridRowsProp = table.rows.filter(row => row.cells.size > 0).map(row => {
    return rowToGridRowData(row)
  }).toArray()

  const classes = useStyles()

  return (
    <DataGrid
      autoHeight
      disableColumnMenu
      className={classes.root}
      density={'compact'}
      headerHeight={0}
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      components={{
        Toolbar: CustomToolbar
      }}
      onCellClick={onCellClick}
    />
  )
}
