import SmartDocPDFViewer, {IOverlay, pdfjs} from '@bgl/textract-doc-viewer'
import autobind from 'autobind-decorator'
import memoize from 'memoize-one'
import React from 'react'
import BusinessModel from '../../domain/businessModel/BusinessModel'
import {OcrDocument} from '../../domain/OcrDocument'
import {adapterFromDocument} from './IOverlayPageTransform'
import {businessModelOverlay} from './OverlayFactory'

interface BusinessModelDocViewerProps {
  pdfBlob: Blob,
  fileName: string,
  document: OcrDocument
  businessModel?: BusinessModel
  onDocumentReady?: (pdf: pdfjs.PDFDocumentProxy) => void
  onSelecting?: (selectingItems: IOverlay[]) => void
  onSelectionFinish?: (selectedItems: IOverlay[]) => void
  onPasswordCancel?: () => void
}

@autobind
export default class BusinessModelDocViewer extends React.Component<BusinessModelDocViewerProps> {
  constructor(props: BusinessModelDocViewerProps) {
    super(props)
  }

  private pdfViewer: SmartDocPDFViewer | null = null

  clearSelection() {
    if (this.pdfViewer) {
      this.pdfViewer.clearSelection()
    }
  }

  scrollIntoView(overlayId?: string) {
    if (this.pdfViewer) {
      this.pdfViewer.scrollIntoView(overlayId)
    }
  }

  selectOverlays(fieldIds: Set<string>) {
    if (this.pdfViewer && this.props.businessModel) {
      const overlayIds = this.props.businessModel.modelSpecificLineItems().linesIdByFieldIds(Array.from(fieldIds))
      this.pdfViewer.selectOverlays(new Set(overlayIds))
    }
  }

  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
  private readonly buildOverlay = memoize((businessModel?: BusinessModel) => businessModelOverlay(this.props.document, businessModel))

  render() {
    const overlays = this.buildOverlay(this.props.businessModel)
    return (
      <SmartDocPDFViewer
        ref={ref => {
          this.pdfViewer = ref
        }}
        pdfBlob={this.props.pdfBlob}
        fileName={this.props.fileName}
        overlays={overlays}
        pageTransforms={adapterFromDocument(this.props.document)}
        onDocumentReady={this.props.onDocumentReady}
        onSelecting={this.props.onSelecting}
        onSelectionFinish={this.props.onSelectionFinish}
        onPasswordCancel={this.props.onPasswordCancel}
      />
    )
  }
}
