import {List} from 'immutable'
import {IBusinessModel, IModelType} from '../../types/ISmartDocsResult'
import BusinessModel from '../BusinessModel'
import {DateField, DoubleField, StringField} from '../DetectedField'
import {ASICFeeMapper} from './ASICFeeMapper'

export class ASICFee extends BusinessModel{
    constructor(
        modelType: IModelType,
        version: string,
        readonly totalDue?:DoubleField,
        readonly companyName?:StringField,
        readonly issueDate?:DateField,
        readonly dueDate?:DateField,
        readonly acn?:StringField,
        readonly companyRegisteredAddress?:StringField,
        readonly companyOfficeholder?:List<StringField>,
        readonly companyOfficeholderDOB?:List<DateField>,
        readonly companyMember?:List<StringField>
    ) {
        super(IModelType.ASICFee, version)
    }

    copy({
        modelType = this.modelType,
        version = this.version,
        totalDue = this.totalDue,
        companyName = this.companyName,
        issueDate = this.issueDate,
        dueDate = this.dueDate,
        acn = this.acn,
        companyRegisteredAddress = this.companyRegisteredAddress,
        companyOfficeholder = this.companyOfficeholder,
        companyOfficeholderDOB = this.companyOfficeholderDOB,
        companyMember = this.companyMember
         }):ASICFee{
    return new ASICFee(
        modelType,
        version,
        totalDue,
        companyName,
        issueDate,
        dueDate,
        acn,
        companyRegisteredAddress,
        companyOfficeholder,
        companyOfficeholderDOB,
        companyMember
        )
    }

    toJson(): IBusinessModel {
        return ASICFeeMapper.toJson(this)
    }
}