import Grid from '@material-ui/core/Grid'
import { FieldAndColumnName } from 'domain/validator/FieldValidatorDef'
import React, { FC } from 'react'
import { RolloverBenefitStatement } from '../../domain/businessModel/rolloverBenefitStatement/RolloverBenefitStatement'
import { EditorFieldEvents } from '../EditorFieldEvents'
import { FormDateField } from '../FormDateField'
import { FormDoubleField } from '../FormDoubleField'
import { FormStringField } from '../FormStringField'

interface IRolloverBenefitStatementProps extends EditorFieldEvents {
    rolloverBenefitStatement: RolloverBenefitStatement
    className?: string
}

export const RolloverBenefitStatementEditor: FC<IRolloverBenefitStatementProps> = (props: IRolloverBenefitStatementProps) =>{
    const rolloverBenefitStatement = props.rolloverBenefitStatement
    return (
        <form noValidate autoComplete="no">
            <Grid container spacing={8}>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.RolloverBenefitStatement_ReceivingFundName}
                        label={'Receiving Fund Name'}
                        events={props}
                        field={rolloverBenefitStatement.receivingFundName}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.RolloverBenefitStatement_ReceivingFundABN}
                        label={'Receiving Fund ABN'}
                        events={props}
                        field={rolloverBenefitStatement.receivingFundABN}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.RolloverBenefitStatement_TransferringFundName}
                        label={'Transferring Fund Name'}
                        events={props}
                        field={rolloverBenefitStatement.transferringFundName}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.RolloverBenefitStatement_TransferringFundABN}
                        label={'Transferring Fund ABN'}
                        events={props}
                        field={rolloverBenefitStatement.transferringFundABN}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.RolloverBenefitStatement_Tfn}
                        label={'TFN'}
                        events={props}
                        field={rolloverBenefitStatement.tfn}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormStringField
                        id={FieldAndColumnName.RolloverBenefitStatement_FullName}
                        label={'Full Name'}
                        events={props}
                        field={rolloverBenefitStatement.fullName}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDateField
                        id={FieldAndColumnName.RolloverBenefitStatement_ServicePeriodStartDate}
                        label={'Service Period Start Date'}
                        events={props}
                        field={rolloverBenefitStatement.servicePeriodStartDate}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.RolloverBenefitStatement_TaxFreeComponent}
                        label={'Tax Free Component'}
                        events={props}
                        field={rolloverBenefitStatement.taxFreeComponent}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.RolloverBenefitStatement_PreservedAmount}
                        label={'Preserved Amount'}
                        events={props}
                        field={rolloverBenefitStatement.preservedAmount}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.RolloverBenefitStatement_ElementTaxedInTheFund}
                        label={'Element Taxed In The Fund'}
                        events={props}
                        field={rolloverBenefitStatement.elementTaxedInTheFund}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.RolloverBenefitStatement_ElementUntaxedInTheFund}
                        label={'Element Untaxed In The Fund'}
                        events={props}
                        field={rolloverBenefitStatement.elementUntaxedInTheFund}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.RolloverBenefitStatement_RestrictedNonPreservedAmount}
                        label={'Restricted Non-Preserved Amount'}
                        events={props}
                        field={rolloverBenefitStatement.restrictedNonPreservedAmount}
                        className={props.className}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDoubleField
                        id={FieldAndColumnName.RolloverBenefitStatement_UnrestrictedNonPreservedAmount}
                        label={'Unrestricted Non-Preserved Amount'}
                        events={props}
                        field={rolloverBenefitStatement.unrestrictedNonPreservedAmount}
                        className={props.className}
                    />
                </Grid>
            </Grid>
        </form>
    )
}
