import {PageVisitor} from "../../domain/DocumentVisitor"
import {Page} from "../../domain/ocr/Page"
import {OcrDocument} from "../../domain/OcrDocument"
import { IPageTransform, PageTransformCalculator } from "../../domain/ocr/PageTransform"

class PageToTransform implements PageVisitor<IPageTransform> {
  visitPage(page: Page): IPageTransform {
    return PageTransformCalculator.fromPage(page).toTransform()
  }
}

export function adapterFromDocument(document: OcrDocument): IPageTransform[] {
  return document.accept(new PageToTransform()).toArray()
}
