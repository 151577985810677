import {Grid, TextField} from '@material-ui/core'
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton
} from '@material-ui/data-grid'
import React from 'react'
import {BusinessModelType} from '../../domain/businessModel/businessModelType/BusinessModelType'
import {BusinessModelTypePageTable} from '../../domain/businessModel/businessModelType/BusinessModelTypePageTable'

interface IBusinessModelTypeEditorProps {
    businessModelType: BusinessModelType
}

export const BusinessModelTypeEditor = (props: IBusinessModelTypeEditorProps) => {

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <GridToolbarExport/>
            </GridToolbarContainer>
        )
    }

    function renderLongTextCell(params: GridCellParams) {
        return (
            <span title={params.value as string} style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>{params.value}</span>
        )
    }


    const columns: GridColDef[] = BusinessModelTypePageTable.toGridColDef(renderLongTextCell)
    const rows = props.businessModelType.businessModelTypePages.toGridRowsProp()

    return (
        <form noValidate autoComplete="no">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label={'Model Name'}
                        defaultValue={props.businessModelType.modelName}
                        fullWidth
                        multiline
                        placeholder="N/A"
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={'Probability'}
                        defaultValue={props.businessModelType.probability}
                        fullWidth
                        multiline
                        placeholder="N/A"
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                        autoHeight
                        disableColumnMenu
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        components={{
                            Toolbar: CustomToolbar
                        }}
                    />
                </Grid>
            </Grid>
        </form>
    )
}
